import About from './components/About'
import Home from './components/Home'
import FAQ from './components/FAQ'
import UserGuide from './components/UserGuide'
import ReleaseNotes from './components/ReleaseNotes'
import Variants from './components/Variants/Variants'
import GenomeBrowser from './components/Genome/GenomeBrowser';
import Resistance from './components/Resistance/Resistance'
import TBResistancePrediction from './components/Resistance/ResistancePrediction/TBResistancePrediction'
import NTMResistancePrediction from './components/Resistance/ResistancePrediction/NTMResistancePrediction'
import TBPortalsData from './components/Resistance/TBPortalsData/TBPortalsData'
import GWAS from './components/Variants/GWAS/GWAS'
import Clustering from './components/Variants/Clustering/Clustering';
import VariantSearch from './components/Variants/VariantSearch/VariantSearch'
import Distance from './components/Variants/Distance/Distance'
import CohortVisualization from './components/Variants/Visualization/CohortVisualization'
import UserVCFFiles from './components/UserProfiler/VCFManagement/UserVCFFiles'
import AdminVCFFiles from './components/UserProfiler/VCFManagement/AdminVCFFiles'
import UserAdmin from './components/UserProfiler/Admin/UserAdmin'
import ListManagement from './components/UserProfiler/ListManagement/ListManagement'
import LoadTbprofileResults from './components/UserProfiler/Admin/LoadTbprofileResults'
import MgmReference from './components/UserProfiler/Admin/NCBIReferenceMgm/MgmReference'
import UserMetadata from './components/UserProfiler/UserMetadata'
import { Transition } from '@uirouter/react';

export const RELEASE_VER="1.21";

export const homeState = { 
    name: "home", 
    url: "/?orcidcode&orcidaccessdeny", 
    component: Home,
    resolve: [{
        token: 'router',
        deps: ['$transition$'],
        resolveFn: (trans: Transition) => trans.router
    }],
};
export const variantsState = {
    name: "variants", 
    url: "/variants?orcidcode&orcidaccessdeny", 
    component: Variants,
    resolve: [
        {
        token: "router",
        deps: ["$transition$"],
        resolveFn: (trans: Transition) => trans.router,
        },
    ],
};
export const genomeBrowserState = {
    name: "genomeBrowser",
    url: "/GenomeBrowser?orcidcode&orcidaccessdeny",
    component: GenomeBrowser,
    resolve: [
        {
        token: "router",
        deps: ["$transition$"],
        resolveFn: (trans: Transition) => trans.router,
        },
    ],
};
export const resistanceSate = { 
    name: "resistance", 
    url: "/resistance?orcidcode&orcidaccessdeny", 
    component: Resistance,
    resolve: [
        {
        token: "router",
        deps: ["$transition$"],
        resolveFn: (trans: Transition) => trans.router,
        },
    ],
};

export const TBresistancePrediction = { 
    name: "tbresistancePrediction", 
    url: "/tbresistancePrediction?orcidcode&orcidaccessdeny", 
    component: TBResistancePrediction,
    resolve: [{
        token: 'router',
        deps: ['$transition$'],
        resolveFn: (trans: Transition) => trans.router
    }],
};

export const NTMresistancePrediction = { 
    name: "ntmresistancePrediction", 
    url: "/ntmresistancePrediction?orcidcode&orcidaccessdeny?googleaccessdeny?googleaccesscode", 
    component: NTMResistancePrediction,
    resolve: [{
        token: 'router',
        deps: ['$transition$'],
        resolveFn: (trans: Transition) => trans.router
    }],
};

export const tbportalsdata = { 
    name: "tbportalsdata", 
    url: "/tbportalsdata?orcidcode&orcidaccessdeny", 
    component: TBPortalsData,
    resolve: [{
        token: 'router',
        deps: ['$transition$'],
        resolveFn: (trans: Transition) => trans.router
    }],
};

export const GWASState = { 
    name: "GWAS", 
    url: "/GWAS?orcidcode&orcidaccessdeny",
    component: GWAS,
    resolve: [{
        token: 'router',
        deps: ['$transition$'],
        resolveFn: (trans: Transition) => trans.router
    }],
};

export const ClusteringState = { 
    name: "Clustering", 
    url: "/Clustering?orcidcode&orcidaccessdeny",
    component: Clustering,
    resolve: [{
        token: 'router',
        deps: ['$transition$'],
        resolveFn: (trans: Transition) => trans.router
    }],
};

export const DistanceState = { 
    name: "Distance", 
    url: "/Distance?orcidcode&orcidaccessdeny",
    component: Distance,
    resolve: [{
        token: 'router',
        deps: ['$transition$'],
        resolveFn: (trans: Transition) => trans.router
    }],
};
export const VariantSearchState = { 
    name: "VariantSearch", 
    url: "/VariantSearch?orcidcode&orcidaccessdeny",
    component: VariantSearch,
    resolve: [{
        token: 'router',
        deps: ['$transition$'],
        resolveFn: (trans: Transition) => trans.router
    }],
};

export const CohortVisualizationState = { 
    name: "Visualization", 
    url: "/Visualization?orcidcode&orcidaccessdeny",
    component: CohortVisualization,
    resolve: [{
        token: 'router',
        deps: ['$transition$'],
        resolveFn: (trans: Transition) => trans.router
    }],
};

export const UserVCFFilesState = { 
    name: "uservcf", 
    url: "/uservcf",
    component: UserVCFFiles,
    resolve: [{
        token: 'router',
        deps: ['$transition$'], 
        resolveFn: (trans: Transition) => trans.router
    }]
};

export const AdminVCFFilesState = { 
    name: "adminvcf", 
    url: "/adminvcf",
    component: AdminVCFFiles,
    resolve: [{
        token: 'router',
        deps: ['$transition$'], 
        resolveFn: (trans: Transition) => trans.router
    }]
};

export const AdminUserState = { 
    name: "userAdmin", 
    url: "/useradmin",
    component: UserAdmin,
    resolve: [{
        token: 'router',
        deps: ['$transition$'], 
        resolveFn: (trans: Transition) => trans.router
    }]
};

export const ListManagementState = { 
    name: "listmanagement", 
    url: "/listmanagement",
    component: ListManagement,
    resolve: [{
        token: 'router',
        deps: ['$transition$'], 
        resolveFn: (trans: Transition) => trans.router
    }]
};

export const LoadTbprofileResultState = { 
    name: "loadtbprofileresults", 
    url: "/loadtbprofileresults",
    component: LoadTbprofileResults,
    resolve: [{
        token: 'router',
        deps: ['$transition$'], 
        resolveFn: (trans: Transition) => trans.router
    }]
};

export const MgmReferenceState = { 
    name: "mgmreference", 
    url: "/mgmreference",
    component: MgmReference,
    resolve: [{
        token: 'router',
        deps: ['$transition$'], 
        resolveFn: (trans: Transition) => trans.router
    }]
};

export const UserMetadataState = { 
    name: "usermetadata", 
    url: "/usermetadata",
    component: UserMetadata,
    resolve: [{
        token: 'router',
        deps: ['$transition$'], 
        resolveFn: (trans: Transition) => trans.router
    }]
};

export const userguideState = { 
    name: "userguide", 
    url: "/userguide?orcidcode&orcidaccessdeny", 
    component: UserGuide, 
    resolve: [{
        token: 'router',
        deps: ['$transition$'], 
        resolveFn: (trans: Transition) => trans.router
    }]
};
export const faqState = { 
    name: "faq", 
    url: "/faq?orcidcode&orcidaccessdeny", 
    component: FAQ, 
    resolve: [{
        token: 'router',
        deps: ['$transition$'], 
        resolveFn: (trans: Transition) => trans.router
    }]
};
export const aboutState = {
    name: "about",
    url: "/about?orcidcode&orcidaccessdeny",
    component: About,
    resolve: [{
      token: "router",
      deps: ["$transition$"],
      resolveFn: (trans: Transition) => trans.router,
    }]
};
export const releasenotesState = {
    name: "releasenotes",
    url: "/releasenotes?orcidcode&orcidaccessdeny",
    component: ReleaseNotes,
    resolve: [{
      token: "router",
      deps: ["$transition$"],
      resolveFn: (trans: Transition) => trans.router,
    }]
};
