import * as React from "react";

import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import BlockUi from 'react-block-ui';
import { Dna } from  'react-loader-spinner';
import UserSession from '../../../services/UserSession';
import axios from "axios";
import validator from 'validator';
import queryString from 'query-string';
import VCF from '../../../domain/VCF';
import SampleList from "../../../domain/SampleList";
import Select, {SingleValue, createFilter} from 'react-select';
import Cohort from "../../../domain/Cohort";
import { CSVLink } from "react-csv";
import GenePosition from "../../../domain/GenePosition";
import DistanceDetailDiff from "../../../domain/DistanceDetailDiff";
import DistanceData from "../../../domain/DistanceData";
import { UISref, Transition} from "@uirouter/react";
import SearchableListData from "../../../domain/SearchableListData";
import GAPSimpleTable from '../../Library/GAPSimpleTable';
import Graph, {NetworkEvents} from "react-graph-vis";
import DistanceDiffTable  from './DistanceDiffTable'
import API_URL from '../../../services/Enviroment';
import vis from "vis";
import {removeOrcidCode} from '../../utils/gapCommon';
import './Distance.css';
import 'vis-network/dist/dist/vis-network.css';


type DistanceState = {
    blocking: boolean,
    selectedncbivalue: string,
    displaySviewPosition : string,
    selectedvcffile : string,
    resultfound: boolean,
	diffresultfound: boolean,
    maxthreshold: string,
    thresholdNumber?: number,
	isValidthreshold:boolean,
	hasColorType: boolean,
	colorDD: string,
	distancetype: string,
	physics: boolean,
	advancedOptions: boolean,
	showbox: boolean,
	selectedSampleid?: SearchableListData,
	selectedComparedSampleid?: SearchableListData,
	selectedSamplelist: string, 
	selectedCohort: string, 
    blockmessage: string,
    seachbyname: string,
    placeholdname: string,
    errors: string
}

const distanceOptions = {
	autoResize: true,
	layout: {
		randomSeed: 2,
	},
	physics: {
		enabled:  false,
		forceAtlas2Based: {
			gravitationalConstant: -250,
			centralGravity: 0.005,
			springLength: 230,
			springConstant: 0.18,
			avoidOverlap: 1.5
		},
		maxVelocity: 146,
		solver: 'forceAtlas2Based',
		timestep: 0.35,
		stabilization: {
			enabled: true,
			iterations: 300,
			updateInterval: 25
		}
	},
	configure: {
		enabled: false,
		filter: 'physics',
		showButton: false
	},
	interaction: {
		hover: true,
		navigationButtons: true,
	},
	edges:{
		arrows: {
			to: { enabled: false }
		  }
	},
};

const nodata_color = "gray";
const colors = ["#2B7CE9", "#5A1E5C", "#C5000B", "#109618", "chocolate", "lime", "purple", "olive", "maroon"];

const SampleDistanceColumns = [
	{
		dataField: 'id', text: 'Sample ID', sort: true, headerAlign: 'center',
		headerStyle: { verticalAlign: 'middle', width: '80px' },
		headerClasses: "tablecellwrap", classes: 'tablecellwrap'
	},
	{
		dataField: 'endid', text: 'Sample ID', sort: true, headerAlign: 'center',
		headerStyle: { verticalAlign: 'middle', width: '150px' },
		headerClasses: "tablecellwrap", classes: 'tablecellwrap'
	},
	{
		dataField: 'value', text: 'Edit Distance', sort: true, headerAlign: 'center',
		headerStyle: { verticalAlign: 'middle', width: '150px' },
		headerClasses: "tablecellwrap", classes: 'tablecellwrap'
	},
];

const defaultSorted = [{
	dataField: 'id',
	order: 'asc'  
  }];

export default class Distance  extends React.Component <Transition, DistanceState> {
    usersession = UserSession.getUserSession();
    vcffiles = Array<VCF>();
	selectedVCFfilename="";
    sampleLists = Array<SampleList>();
    sampleids = Array<SearchableListData>();

	colorTypes: {[key: string]: String} = {};
	cohorts = Array<Cohort>();

	searchResults=Array<DistanceData>();
	//distancediffAllData= Array<DistanceDetailDiff>();
	distancediffData=Array<DistanceDetailDiff>();
	tabledetaildata=Array<DistanceDetailDiff>();
	//downloaddetaildata = Array<DistanceDetailDiff>();

	selectedID1? : vis.IdType = "";
	selectedID2? : vis.IdType = "";

	networkdata = {
		nodes: Array<vis.Node>(),
		edges: Array<vis.Edge>()
	}

	legendoptions = {};
	legenddata = {
		nodes: Array<vis.Node>(),
		edges: Array<vis.Edge>()
	};
	network =  {}

    constructor(props: Transition) {
        super(props);
       
        this.state = {
            blocking: false,
            selectedncbivalue: "",
            displaySviewPosition: "",
            selectedvcffile: "",
            resultfound: false,
			diffresultfound: false,
            maxthreshold: "",
            thresholdNumber: 15,
			isValidthreshold: false,
			hasColorType: false,
			colorDD: "",
			physics: true,
			advancedOptions: false,
			showbox: false,
			distancetype: "s",
            selectedSampleid: undefined,
			selectedComparedSampleid: undefined,
			selectedSamplelist: "",
			selectedCohort: "",
            seachbyname: "G",
            placeholdname: "",
            blockmessage: "Loading",
            errors: ""
        };
	}

	onSearchVCF =() => {
		var cohort_ssrids = "";
		
		if(this.state.distancetype == 'c') {    //cohort sample
			var cohorsdata = JSON.parse(this.state.selectedCohort) as Cohort;
			cohort_ssrids = cohorsdata.ssrids;
		}
		else if (this.state.distancetype == 'l'){
			var listdata = JSON.parse(this.state.selectedSamplelist) as SampleList;
			cohort_ssrids = listdata.totallistcontent;
		}
		
		var stateJson = {
            blocking:true,
            blockmessage:"Caculating ... ",
            resultfound: false,
			diffresultfound:false,
			physics:true,
            errors:""
        } as DistanceState;

		this.setState(stateJson);

		var selectedVCFfile = JSON.parse(this.state.selectedvcffile) as VCF;

		var comparedsampleidName="";
		if(this.state.distancetype =='a' ){	//all sample
			cohort_ssrids = "all";
			// cohort_ssrids = this.sampleids.map(function(data: SearchableListData){
			// 	return data.label;
			// }).join(",");
		}
		else if(this.state.distancetype =='s' && this.state.selectedComparedSampleid != null && this.state.selectedSampleid != this.state.selectedComparedSampleid){
			comparedsampleidName = this.state.selectedComparedSampleid.label;
		}
		
		var sampleidName="";
		if(this.state.distancetype =='s' && this.state.selectedSampleid != null){
			sampleidName = this.state.selectedSampleid.label;
		}

		let url = API_URL +'distance/getDistance';
		var distantdata = {
			    	"sampleid": sampleidName, 
			    	"comparedsampleid": comparedsampleidName, 
			    	"vcffile": this.selectedVCFfilename, 
			    	"hasmetadata":selectedVCFfile.hasmetadata,
			    	"thresholdNumber": this.state.thresholdNumber,  
			    	"cohort_ssrids": cohort_ssrids
			    };
        axios.post(url, distantdata)
            .then(response => {
					var distanceData = response.data.distance as DistanceData[];
					//this.distancediffAllData = response.data.diff;
					
					if(distanceData != undefined && distanceData.length > 0){
						this.colorTypes = response.data.colors;
						if(Object.values(this.colorTypes)[0] == 'null'){
							stateJson.errors = "Internal error retrieving sample metadata";
						 }
						stateJson.colorDD = Object.keys(this.colorTypes)[0];
						
						if(Object.values(this.colorTypes)[0] == 'null'){
							stateJson.errors = "Internal error retrieving sample metadata";
						}
						
						stateJson.resultfound = true;
						//this.searchResults = distanceData;
						this.searchResults = this.PrepareData(distanceData);
						this.buildEdge(this.state.selectedSampleid?.label);
						this.update_vis_network(stateJson);
						
						// $scope.resultfound = true;
						// $scope.diffresultfound = false;
		
						// setupSearchresulttable();
					}
					else{
						stateJson.errors ="No distance data."
					}

					stateJson.blocking=false;
					this.setState(stateJson);

            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.blocking = false;
                stateJson.errors = error.message;
				this.setState(stateJson);
            });
	}

	PrepareData=(data:  DistanceData[])=>{
		var newdata = Array<DistanceData>();
		for (var i = 0; i < data.length; i++) {
			var olddata = data[i];
			olddata.value=parseFloat(olddata.value.toString());
			
			newdata.push(olddata);
		};
     		
		return newdata;
	};

	selectededge =(edges: string[]) => {
		console.log("selected edges:", edges);

		var stateJson = {
            blocking:true,
            blockmessage:"Selected ... ",
			diffresultfound:false,
            errors:""
        } as DistanceState;

		this.setState(stateJson);

		var found = this.networkdata.edges.find(function(element) {
			 return element.id ==  edges[0];
		   }) as vis.Edge;

		var from_point = found.from;
		var end_point = found.to;
		var sidsStr = end_point + ' ' + from_point;

		let url = API_URL +'distance/getDistanceDetail';
		axios.post(url, { "baseId": end_point, "diffIdsStr": sidsStr, "vcffile": this.selectedVCFfilename })
			.then(response => {
				stateJson.diffresultfound = true;
				this.distancediffData = this.formatDiffData(response.data);
				this.selectedID1 = from_point;
				this.selectedID2 = end_point;

				this.tabledetaildata = this.filterShowBox(this.state.showbox);
				stateJson.blocking =false;
				this.setState(stateJson);
			})
			.catch(error => {
				console.error('There was an error!' + error.message);
				stateJson.blocking = false;
				stateJson.errors = error.message;
				this.setState(stateJson);
			});
	};

	filterShowBox=(status : Boolean)=>{
		var filterDiff = this.distancediffData;
		if(!status){
			filterDiff = this.distancediffData.filter(x=>x.GT1 != "0/1" && x.GT2 != "0/1");
		}
		
		return filterDiff;
	}

	formatDiffData=(data: Array<DistanceDetailDiff>)=>{
		var newdata = Array<DistanceDetailDiff>();
		for (var i = 0; i < data.length; i++) {
			var olddata = data[i];
			olddata.POS=parseFloat(olddata.POS.toString());
			
			olddata.NewGT1 = olddata.GT1.replace("/","\\"); 
			olddata.NewGT2 = olddata.GT2.replace("/","\\"); 
			
			newdata.push(olddata);
		};
     		
		return newdata;
	}

	getDistanceNetwork = (nw: vis.Network) =>{
		this.network = nw;
		
		nw.setOptions({physics: true});
	}

	update_vis_network = (stateStatus: DistanceState) => {
		var group_data = this.createLegendGroup(stateStatus.colorDD);
		this.networkdata = group_data[2];
		//Legend network
		this.legendoptions = {
			physics: {
				enabled: true,
			},

			interaction: {
				zoomView: true,
				dragNodes: false,
				dragView: true,
			},

			groups: group_data[0],
		};

		this.legenddata = group_data[1];
	};

	createLegendGroup =(colortype: string) =>{
		var networkdata_clone = JSON.parse(JSON.stringify(this.networkdata));
		var networkdata_legend = {
			nodes:Array<vis.Node>(),
			edges:Array<vis.Edge>()
		};
		var groupStr ='{';
		if(colortype == undefined || colortype == '') {
			groupStr="{}";
		}
		else{
			var x = 40;
		   	var y = 200;
		   	var step = 70;
			var legendString = this.colorTypes[colortype];
			var legends = legendString.split("&");

			legends.push("No Data");
			
			for (var i = 0; i < legends.length; i++) {
				var legend = legends[i];
				legend = legend.replace(/"/g,'');
				
				var node_color = colors[i];

				for(var id in networkdata_clone.nodes){
					var node_color = colors[i];
					var node = networkdata_clone.nodes[id];
					var title = node.title;
					
					if(title === "No Data"){
						legendgValue = title;
						node_color = nodata_color;
					}
					else {
						node_color = colors[i];
						var startPos = title.indexOf(colortype) + colortype.length + 1;
						var endpos =  title.indexOf('\n', startPos);
						var legendgValue = title.substring(startPos, endpos);
						if(endpos <0 ){
							legendgValue = title.substring(startPos);
						}
					}

					var valueList = legendgValue.split("&");
					if (valueList.includes(legend)) { 
						node["color"] = {
							background:node_color,
							border: node_color
						}

						node["font"] = '18px verdana white';
						// if(i == 5){
						// 	node["font"] = '18px verdana black';
						// }
						// else{
						// 	node["font"] = '18px verdana white';
						// }
					}
				}
				
				var legend_color = colors[i];
				if(legend === "No Data"){
					legend_color = nodata_color;
				}
				var colorshape = {
					shape: 'square',
					color: legend_color
				}
				 
				 var groupStr = groupStr + '"' + legend + '":' + JSON.stringify(colorshape) + ",";
				 var idnumber = 1000+ i;
				 networkdata_legend.nodes.push(
					 {
						 id: idnumber, 
						 x: x, 
						 y: y + step *i, 
						 group:legend, 
						 label: legend, 
						 title:legend,  
						 value: 1, 
						 size:20, 
						 fixed: false, 
						 physics: false
					});
			}

			groupStr = groupStr.substring(0, groupStr.length-1) + '}';
		}
		return [JSON.parse(groupStr), networkdata_legend, networkdata_clone] ;
	}


	buildEdge = (endName?: string) =>{
		this.networkdata = {
				nodes:[],
				edges:[]
		}
		var nodes = JSON.parse(JSON.stringify(this.searchResults));
		
		var maxvalue = nodes[nodes.length - 1]["value"];
		var minivalue = nodes[0]["value"];
		var maxwidth=10;
		var miniwidth=1;
		
		for (var i = 0; i < nodes.length; i++) {
		   if((endName=="" && nodes[i]['endid'] != undefined) || (endName != "" && nodes[i]["id"] != endName)){
			   var value = parseInt(nodes[i]['value']);
			   var displaywidth = miniwidth;
			   if(value < maxwidth) {
				   displaywidth = maxwidth-3;
			   }

			   var edge = {
					   from: nodes[i]['id'],
					   to: nodes[i]['endid'], 
					   label: value.toString(),
					   length: value,
					   width: displaywidth,
			   } as vis.Edge; 
			   this.networkdata.edges.push(edge);
		   	}
		   	if(this.networkdata.nodes.filter(e=>e.id == nodes[i].id).length==0){
				this.networkdata.nodes.push(nodes[i]);
		   	}
		}	
	}

	events = {
		selectEdge: (params: vis.Properties) => {
			var edges = params.edges;
			if (edges.length == 1) {
				this.selectededge(edges);
			}
		},
		configChange: (params: vis.Options) => {
			var pcs = params.physics;
			this.setState({ physics: pcs.enabled });
		},
	};

    onChangevcf = (event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;
      
		var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            resultfound: false,
			diffresultfound:false,
			selectedvcffile: target.value,
            errors:""
        } as DistanceState;

		this.setState(stateJson);

    	this.searchResults = [];
		var selectedVCFfile = JSON.parse(target.value) as VCF;
		var vcffilename = selectedVCFfile.filename;
		if (selectedVCFfile.owner != null && selectedVCFfile.owner != "system") {
			vcffilename = selectedVCFfile.owner + "/" + vcffilename;
		}
		this.selectedVCFfilename = vcffilename;
		this.getSampleIds(false, stateJson);
	}

    loadvcffile = () => {
        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            resultfound: false,
            errors:""
        } as DistanceState;

        this.setState(stateJson);

        let url = API_URL +'genomics/VCFfiles';
        axios.post(url, {type:'vcf'})
            .then(response => {
                this.vcffiles = response.data;
                var defaultvcffile=this.vcffiles[0];
                for (var i = 0; i < this.vcffiles.length; i++) {
                    var vcf = this.vcffiles[i];
                    if (vcf.isdefault) {
                        defaultvcffile = vcf;
                        break;
                    }
                }

				var vcffilename = defaultvcffile.filename;
				if (defaultvcffile.owner != null && defaultvcffile.owner != "system") {
					vcffilename = defaultvcffile.owner + "/" + vcffilename;
				}

				this.selectedVCFfilename = vcffilename;
                stateJson.selectedvcffile = JSON.stringify(defaultvcffile);
                this.getSampleIds(false, stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.blocking = false;
                stateJson.errors = error.message;
            });
    }

    getSampleIds =(ismergedvcf: boolean, stateStatus: DistanceState) => {
        let url = API_URL +'distance/getSampleIds';
        axios.post(url, {"vcffile": this.selectedVCFfilename, "ismergedvcf": ismergedvcf})
            .then(response => {
				var namepositions = response.data as Array<GenePosition>;
				this.sampleids = Object.entries(namepositions).map(([key, value], i) => {
					return {"value": value.Name, "label": value.Name}
				});
                this.loadUserSampleList(stateStatus);
            })
            .catch(error => {
				this.setState ({blocking: false, errors: error.response.data});
                console.error('There was an error!' + error.message);
            });
    }

    loadUserSampleList = (stateStatus : DistanceState) => {
        let url = API_URL +'listmanagement/getList';
        let selectedvcfid = (JSON.parse(stateStatus.selectedvcffile) as VCF).vcfid;
        axios.get(url, {params: {vcfid: selectedvcfid}})
            .then(response => {
                var userlist = response.data;
        		var userSampleList = userlist.filter(function(list : SampleList){
        			return (list.listtype == 'Sample');
        		});
        		
                this.sampleLists = userSampleList;
                stateStatus = this.setupMaxthreshold(stateStatus);
                stateStatus.blocking = false;
                this.setState (stateStatus);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState ({errors: 'There was an error!' + error.message});
            });
	}

    setupMaxthreshold =(stateStatus : DistanceState) => {
		// if($scope.distancetype=='s'){
		// //	$scope.maxthreshold=portalConstant.default_maxthreshold;
		// 	$scope.thresholdNumber = 30;
		// }
		// else if($scope.distancetype=='c'){
		// 	 $scope.maxthreshold=50;
		// 	 $scope.thresholdNumber = portalConstant.default_threshold;
		// 	 if($scope.cohort_data.ssrids != undefined ){
		// 		 var total = $scope.cohort_data.total
		// 		 if(total > 500) $scope.maxthreshold=30;
		// 	 }
		// }
		// else if($scope.distancetype=='l'){
		// 	$scope.maxthreshold=50;
		// 	$scope.thresholdNumber = portalConstant.default_threshold;
		// 	if($scope.list_data.listcontent != undefined ){
		// 		var total = $scope.list_data.listnumber;
		// 		if(total > 500) $scope.maxthreshold=30;
		// 	}
		// }

        return stateStatus;
	}

	onThresholdNumber = (event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;

		if (validator.isInt (target.value)) {
			this.setState({ thresholdNumber:  parseFloat(target.value), isValidthreshold:true});
		} else {
			this.setState({ thresholdNumber: parseFloat(target.value), isValidthreshold: false });
		}
	}

	onChangeColor =(event: React.FormEvent<HTMLSelectElement>) => {
		let target = event.target as HTMLSelectElement;
		let name = target.options[target.options.selectedIndex].text;
		var stateJson = {
            colorDD: name
        } as DistanceState;
		this.update_vis_network(stateJson);
		this.setState ({colorDD: target.value});
	}

	onChangeList =(event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;
		var maxthresholdvalue = "";
		if(target.value == 'all'){
			maxthresholdvalue = "15";
			
		}
		this.setState ({thresholdNumber:15, maxthreshold: maxthresholdvalue, selectedSamplelist: target.value});
	}

	onChangeCohort=(event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;

		this.setState ({selectedCohort: target.value});
	}

	onDistancetypeChange =  (event: React.FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({distancetype:target.value});
    }

	onChangeSampleId =(option: SingleValue<SearchableListData>) => {
		if(option == null){
			this.setState({selectedSampleid:undefined});
		}
		else{
        	this.setState({selectedSampleid: {value:option?.value, label:option?.label}});
		}
    }

	onPhysicsChanged =(event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;
		var checked = !this.state.physics;
		var distance_network = this.network as vis.Network;
		distance_network.setOptions({physics: checked});
		this.setState({physics: checked});
	}

	onAdvancedOptions =(event: React.FormEvent<HTMLInputElement>) => {
		var checked = !this.state.advancedOptions;
		var distance_network = this.network as vis.Network;
		var options = {
			configure: {
			  enabled: checked,
			}
		  }

		distance_network.setOptions(options);
		this.setState({advancedOptions: checked});
	}

	onChangeComparedSampleId =(option: SingleValue<SearchableListData>) => {
		if(option == null){
			this.setState({selectedComparedSampleid:undefined});
		}
		else{
        	this.setState({selectedComparedSampleid: {value:option?.value, label:option?.label}});
		}
    }

	onShowbox =(event: React.FormEvent<HTMLInputElement>) => {
		var checked = !this.state.showbox;
		this.tabledetaildata = this.filterShowBox(checked);
        this.setState({showbox: checked});
    }

	onReloadCohorts =() => {   //will add a call to get latest cohorts from DEPOT.
		if(this.usersession.isverified){
            this.setState({blocking: true, blockmessage:"Refresh cohorts..."}); 
            let url = API_URL +'genomics/reloadDepotData';
            axios.post(url, {email:this.usersession.email})
                .then(response => {
                    this.setState({blocking: false}); 
                    this.usersession.Depot_cohorts = response.data;
	        	    sessionStorage.setItem("userSession", JSON.stringify(this.usersession));
                    this.cohorts = this.usersession.Depot_cohorts;
                })
                .catch(error => {
                    console.error('There was an error!' + error.message);
                    this.setState ({errors: 'There was an error!' + error.message, blocking: false});
                });
		}
	}

    componentDidMount(){	
		this.loadvcffile();
	}

    render() {
		removeOrcidCode("Distance", this.props);
		this.usersession = JSON.parse(sessionStorage.userSession);
		this.cohorts =  this.usersession.Depot_cohorts;
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper"/>}>
				<div className="gap-page-content">
     				<div className="container">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className='breadcrumb-item'>
                                        <UISref to="home"><a>Home</a></UISref>
                                    </li>
                                    <li className='breadcrumb-item'>
                                        <UISref to="variants"><a>Variant</a></UISref>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Distance</li>
                                </ol>
                            </nav>
                            <h1>Distance Calculation</h1>
                            <p>
                                Compute the SNP distance between samples. The SNP distance is computed using the Plink 2.0 –make-king-table counts command. See <a href='https://www.cog-genomics.org/plink/2.0/distance' target='_blank'>https://www.cog-genomics.org/plink/2.0/distance</a> for details about the distance calculation.
                            </p>
                            <hr />
                            <form name="vcfForm">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label htmlFor="vcffile">Reference VCF File</label>
                                            <select
												id="vcffile"
												className="form-select"
                                                value={this.state.selectedvcffile}
                                                onChange={this.onChangevcf}>
                                                {
                                                    Object.entries(this.vcffiles).map(([key, value], i) => (
                                                        <option key={key} value={JSON.stringify(value)}>{value.displayname}</option>
                                                    ))
                                                }
                                            </select>
                                    </div>
                               
									<div className="col-md-3">
										<label htmlFor="thresholdNumber">Sample Distance Threshold</label>
										<Tooltip title="The maximum threshold number for all sample distances is 15." className="margin-top--10">
											<HelpOutlineIcon />
										</Tooltip>
										<input id="thresholdNumber" type="number" className="form-control" required
											name="thresholdNumber" 
											placeholder={"Please input a number between (0- " + this.state.maxthreshold + ")."} 
											min="0" 
											max={this.state.maxthreshold} 
											value={this.state.thresholdNumber} 
											onChange={this.onThresholdNumber}
											step="1" />
									
									</div>
									
									{ Object.keys(this.colorTypes).length >0 &&
										<div className="col-md-3">
											<label htmlFor="colorDD">Color By Metadata</label>
											<select
											    id ="colorDD"
                                                className="form-select"
                                                value={this.state.colorDD}
                                                onChange={this.onChangeColor}>
                                                {
                                                    Object.entries(this.colorTypes).map(([key, value], i) => (
                                                        <option key={key} value={JSON.stringify(value)}>{key}</option>
                                                    ))
                                                }
                                            </select>
										</div>
									}
								</div>

								<div className="row margin-top-20" >
									<div className="row margin-top-10" >
										<div className="col-md-4">
											<input id="cb_sample" type="radio" name="distancetype" value="s" checked={this.state.distancetype === "s"}
												onChange={this.onDistancetypeChange} />
											<span className="margin-left-10" >
												<label htmlFor="cb_sample">Sample Distance</label>
											</span>
										</div>
										{this.usersession.isverified &&
											<div className="col-md-4">
												<input id="cb_list" type="radio" name="distancetype" value="l" checked={this.state.distancetype === "l"}
													onChange={this.onDistancetypeChange} />
												<span className="margin-left-10" >
													<label htmlFor="cb_list">Sample List Distance</label>
												</span>
											</div>
										}

										<div className="col-md-2" >
											<div>
												<input id="cb_sample" type="radio" name="distancetype" value="a" checked={this.state.distancetype === "a"}
													onChange={this.onDistancetypeChange} />
												<span className="margin-left-10" >
													<label htmlFor="cb_sample">All Sample Distances</label>
												</span>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-4">
											<fieldset className="custom_fieldset" style={{ "height": "160px" }} disabled={this.state.distancetype != 's'}>
												<div className="row col-md-12">
													<div className="form-group margin-top-10">
														<label htmlFor="sampleid">Sample ID</label>
														<Select
															inputId="sampleid"
															className="searchableLay1"
															options={this.sampleids}
															value={this.sampleids.filter(({ value }) => value === this.state.selectedSampleid?.value)}
															onChange={(option) => this.onChangeSampleId(option)}
															isDisabled={this.state.distancetype != 's'}
															isSearchable={true}
															isClearable={true}
															filterOption={createFilter({ ignoreAccents: false })}
															placeholder={"Select Sample Id ..."} />
													</div>
												</div>
												<div className="row col-md-12">
													<div className="form-group margin-top-10">
														<label htmlFor="c-sampleid">Comparison Sample ID (optional)</label>
														<Select
															inputId="c-sampleid"
															className="searchableLay2"
															options={this.sampleids}
															value={this.sampleids.filter(({ value }) => value === this.state.selectedComparedSampleid?.value)}
															onChange={(option) => this.onChangeComparedSampleId(option)}
															isDisabled={this.state.distancetype != 's'}
															isSearchable={true}
															isClearable={true}
															filterOption={createFilter({ ignoreAccents: false })}
															placeholder={"Select Sample Id ..."} />
													</div>
												</div>
											</fieldset>
										</div>
										{this.usersession.isverified &&
											<div className="col-md-4">
												<fieldset className="custom_fieldset" style={{ "height": "160px" }} disabled={this.state.distancetype != 'l'}>
													<div className="form-group margin-top-10" >
														<label htmlFor="sampleList">Sample List</label>
														<select
															id="sampleList"
															className="form-select"
															value={this.state.selectedSamplelist}
															onChange={this.onChangeList}>
															<option value="">Select a Sample List ...</option>
															{
																Object.entries(this.sampleLists).map(([key, value], i) => (
																	<option key={key} value={JSON.stringify(value)}>{value.listname}</option>
																))
															}
														</select>
													</div>
												</fieldset>
											</div>
										}
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<button id="search" type="button" onClick={this.onSearchVCF}
											className=" btn btn-primary" 
											disabled={(this.state.distancetype == 's' && this.state.selectedSampleid == undefined) ||
													  (this.state.distancetype == 'c' && this.state.selectedCohort == '') ||
													  (this.state.distancetype == 'l' && this.state.selectedSamplelist == '') ||
													  this.state.thresholdNumber == undefined 
													 }>Calculate
										</button>
									</div>
								</div>

								<div className="row">
                                    <span className="redcolor">{this.state.errors}</span>
                                </div>
								
								{this.state.resultfound &&
									<div>
										<br></br>
										<div className="row">
											<div className="col-md-6">
												<input
													id="advancedOptions"
													type="checkbox"
													name="advancedOptions"
													checked={this.state.advancedOptions}
													onChange={this.onAdvancedOptions}
												/>
												<label htmlFor="advancedOptions">&nbsp; Advanced Graph Options </label>
											</div>
											<div className="col-md-5 text-align margin-left--10">
												<input
													id="enabledphysics"
													type="checkbox"
													name="physics"
													checked={this.state.physics}
													onChange={this.onPhysicsChanged}
												/>
												<label htmlFor="enabledphysics">&nbsp;Enable Physics</label>
											</div>
											
										</div>
										<div className="row">
											<div className="distance-display">
												<div id="distancenetwork" className="distance-layout">
													<Graph
														graph={this.networkdata}
														options={distanceOptions}
														events={this.events}
														getNetwork={network => {this.getDistanceNetwork(network)}}
													/>
												</div>
											</div>
											<div className="legend-display">
												<div id="legendnetwork" className="legend-layout">
													<Graph graph={this.legenddata} options={this.legendoptions} />
												</div>
											</div>
										</div>
									
										{this.state.diffresultfound &&
											<div>
												<div className="row"> 
													<ul className="list-inline margin-bottom-0">
														<li>
															<span className="differnt_title">
																Relationship details between { this.selectedID1 } and {this.selectedID2}
															</span>
														</li>
														<li>
															<input
																id="showbox"
																type="checkbox"
																name="showbox"
																checked={this.state.showbox}
																onChange={this.onShowbox}
															/>
															<label htmlFor="showbox">&nbsp; Show Ambiguous </label>
														</li>
														<li className="sampleDistanceDownload">
															<CSVLink filename={"SampleRelationshipDetails.csv"} data={this.tabledetaildata} className="btn btn-success"
																headers={[
																	{ label: "POS", key: "POS" },
																	{ label: "REF", key: "REF" },
																	{ label: "ALT", key: "ALT" },
																	{ label: "GT1", key: "NewGT1" },
																	{ label: "GT2", key: "NewGT2" },
																	{ label: "Impact", key: "Impact" },
																	{ label: "AA Change", key: "AAChange" },
																	{ label: "Gene ID", key: "GeneID" },
																	{ label: "Protein ID", key: "ProteinID" },
																	{ label: "ProteinName", key: "ProteinName" },
																]}>
																<span className="glyphicon glyphicon-save"></span>Download
															</CSVLink>
														</li>
													</ul>
												</div>
												<div className="row">
													<DistanceDiffTable Results={this.tabledetaildata} />
													<hr/>
												</div>
											</div>
										}
										<div className="row"> 

											<div className="margin-top-25" style={{ display: "flex" }} >
												<div className="col-md-11">
													<span className="sampleDistance" >
														Sample Distance
													</span>
												</div>
												<div className="sampleDistanceDownload">
													<CSVLink filename={"SampleDistance.csv"} data={this.searchResults.filter(({ endid }) => endid != undefined)} className=" btn btn-success"
														headers={[
															{ label: "Sample ID", key: "id" },
															{ label: "Sample ID", key: "endid" },
															{ label: "Edit Distance", key: "value" },
														]}>
														<span className="glyphicon glyphicon-save"></span>Download
													</CSVLink>
												</div>
											</div>
											<GAPSimpleTable id="tableid" tableResults={this.searchResults.filter(({ endid }) => endid != undefined)} columns={SampleDistanceColumns} defaultSorted={defaultSorted} />
										</div>
									</div>
								}
                            </form>
                        </div>
                    </div>
                </BlockUi>
            </div>
        );
    }
  }
