import React from "react";
import BlockUi from 'react-block-ui';
import Modal from 'react-bootstrap/Modal'
import { Dna } from  'react-loader-spinner';
import axios from "axios";
import { UISref, Transition} from "@uirouter/react";
import Select, {SingleValue, createFilter} from 'react-select';
import { CSVLink } from "react-csv";
import { Bar } from 'react-chartjs-2';
import VariantTable from './VariantTable';
import API_URL from '../../../services/Enviroment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import NcbiSViewer from '../../Genome/NcbiSViewer';
import NCBIReference from '../../../domain/NCBIReference'
import VCF from '../../../domain/VCF'
import UserSession from '../../../services/UserSession'
import queryString from 'query-string';
import SampleList from '../../../domain/SampleList'
import SearchableListData from "../../../domain/SearchableListData"
import Variant from "../../../domain/Variant"
import VariantDetail from "../../../domain/VariantDetail"
import GenePosition from "../../../domain/GenePosition"
import GAPSimpleTable from '../../Library/GAPSimpleTable';
import {removeOrcidCode, updateTableRow} from '../../utils/gapCommon';
import './VariantSearch.css'
import { BsWindowSidebar } from "react-icons/bs";

type impactType = {
    name: string,
    id: string,
    selected: boolean
}

type VariantSearchState = {
    blocking: boolean,
    selectedncbivalue: string,
    displaySviewPosition : string,
    selectedvcffile : string,
    ncbiName : string,
    displaydetail: boolean,
    resultfound: boolean,
    rangefrom: string,
    rangeto: string,
    seachbyname: string,
    placeholdname: string,
    selectedGeneName?: SearchableListData,
    displayResults : Array<Variant>,
    impacts: Array<impactType>,
    reloadSview: number,
    errors: string,
    showCohortCreateError: boolean
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const barchartoption = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            text: '',
        },
    },
    scales: {
        y: {
            stacked: true
        }
    }
}

const VariantDetailColumns = [
    {
        dataField: 'SampleId', text: 'Sample ID', sort: true, headerAlign: 'center',
        headerStyle: { verticalAlign: 'middle', width: '80px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    {
        dataField: 'Position', text: 'Position', sort: true, headerAlign: 'center',
        headerStyle: { verticalAlign: 'middle', width: '150px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    {
        dataField: 'RefAllele', text: 'Ref Allele', sort: true, headerAlign: 'center',
        headerStyle: { verticalAlign: 'middle', width: '80px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    {
        dataField: 'AltAllele', text: 'Alt Allele', sort: true, headerAlign: 'center',
        headerStyle: { verticalAlign: 'middle', width: '80px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    {
        dataField: 'GeneName', text: 'Gene Name', sort: true, headerAlign: 'center',
        headerStyle: { verticalAlign: 'middle', width: '80px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    {
        dataField: 'AAChange', text: 'AA Change', sort: true, headerAlign: 'center',
        headerStyle: { verticalAlign: 'middle', width: '100px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    {
        dataField: 'Genotype', text: 'Genotype', sort: true, headerAlign: 'center',
        headerStyle: { verticalAlign: 'middle', width: '100px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
];

const defaultSorted = [{
    dataField: 'P',
    order: 'asc'
}];

export default class VariantSearch  extends React.Component <Transition, VariantSearchState> {
    selectedVCFfilename = "";
    usersession = UserSession.getUserSession();
    NCBIs: { [key: string]: NCBIReference } = {};
    vcffiles = Array<VCF>();
    variantList = Array<SampleList>();
    genenames= Array<SearchableListData>();
    genenames1= Array<String>();
    searchNameArray: {[key: string]: GenePosition} = {};
    variantResults = Array<Variant>();
    selectedrowid = "";
    variantDetail = Array<VariantDetail>();
    barchartdata = {
        labels: Array<string>(),
        datasets:[{
            label: '',
            //backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: Array<number>()
        }]
    }
    barchartlabels = Array<string>();

    constructor(props: Transition) {
        super(props);
        if (sessionStorage.token == null || sessionStorage.token == "") {
            let transition = props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.state = {
            blocking: false,
            selectedncbivalue: "",
            displaySviewPosition: "",
            selectedvcffile: "",
            ncbiName: "",
            displaydetail: false,
            resultfound: false,
            rangefrom: "",
            rangeto: "",
            selectedGeneName: undefined,
            seachbyname: "G",
            placeholdname: "",
            displayResults : Array<Variant>(),
            impacts: Array<impactType>(),
            reloadSview: 1,
            errors: "",
            showCohortCreateError: false
        };
    }

    loadReferences = (param_RefseqID: string) => {
        let url = API_URL +'admin/getAppReferences';
        axios.get(url)
            .then((response) => {
                this.NCBIs = response.data;
                this.loadvcffile(param_RefseqID);
            })
            .catch((error) => {
                console.error("There was an error!" + error.message);
                this.setState({errors: error.message});
            });
    }

    loadvcffile = (param_RefseqID: string) => {
        let url = API_URL +'genomics/VCFfiles';
        axios.post(url, { type: 'vcf' })
            .then(response => {
                this.vcffiles = response.data as Array<VCF>;

                var defaultvcffile = this.vcffiles[0];
                for (var i = 0; i < this.vcffiles.length; i++) {
                    var vcf = this.vcffiles[i];
                    if (vcf.isdefault) {
                        defaultvcffile = vcf;
                        break;
                    }
                }

                var selectedvcf = JSON.parse(JSON.stringify(defaultvcffile));
                var ncbiref = this.NCBIs[selectedvcf.reference].NCBI_Embedded;
                var reloadSview = this.state.reloadSview +1;
                var stateJson = {
                    selectedvcffile: JSON.stringify(defaultvcffile), 
                    selectedncbivalue: ncbiref, 
                    ncbiName: selectedvcf.reference,
                    reloadSview: reloadSview, 
                    errors: "",
                } as VariantSearchState;

                var vcffilename = selectedvcf.filename;
                if (selectedvcf.owner != null && selectedvcf.owner != "system") {
                    vcffilename = selectedvcf.owner + "/" + vcffilename;
                }
		        this.selectedVCFfilename = vcffilename;

                if (param_RefseqID != null && param_RefseqID != '') {
                    this.getGeneNames("R", param_RefseqID, stateJson);
                }
                else {
                    this.getGeneNames("G", "", stateJson);
                }
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({errors: error.message});
            });
    }

    loadNewSession = (param_RefseqID: string) => {
        let url = API_URL + 'login/getnewsession';
        axios.get(url)
            .then((response) => {
                var usersession = response.data;
		        sessionStorage.setItem("userSession", JSON.stringify(usersession));
		        sessionStorage.setItem("token", usersession.gap_token);
                this.loadReferences(param_RefseqID);
               
            })
            .catch((error) => {
                console.error("There was an error! " + error.message);
            });
	}


    impactChecked =(event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;
        var imapctid=target.id as string;  
        var currentimapct = this.state.impacts.find(x=>x.id == imapctid) as impactType;
        currentimapct.selected = !currentimapct.selected;

        var selectedimpacts = this.state.impacts.filter(x=>x.selected);
        
        if(selectedimpacts.length == 0){
            this.setState({displayResults: this.variantResults});
        }
        else {
            var displayResults = this.variantResults.filter(function (item) {
                var re = false;

                var itemImpact = item.Impact;
                if (itemImpact == undefined || itemImpact == null || itemImpact.length == 0) itemImpact = "Other";

                var combineimpacts = itemImpact.split("&");
                for (var key in selectedimpacts) {
                    var f = selectedimpacts[key];
                    if (combineimpacts.includes(f.id)) {
                        re = true;
                        break;
                    }
                }
                return re;
            });

            this.setState({displayResults: displayResults});
        }

        this.setState({impacts: this.state.impacts});
	}

    searchby = (event: React.FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var stateStatus=({
            seachbyname: target.value,
            selectedGeneName: undefined,
            errors: ""
        }) as VariantSearchState;
        stateStatus = this.changesearchtype(stateStatus);
        this.setState(stateStatus);
    }
   
    changeRangeFrom = (event: React.FormEvent <HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        const newvalue = (target.validity.valid) ? target.value : this.state.rangefrom;
        this.setState ({rangefrom: newvalue});
    }

    changeRangeTo = (event: React.FormEvent <HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        const newvalue = (target.validity.valid) ? target.value : this.state.rangeto;
        this.setState ({rangeto: newvalue});
    }    

    createVSList = (row: Variant) => {
        var displayresults = this.state.displayResults;
        var stateJson = {
            blocking:true,
            errors:"",
        } as VariantSearchState;

        this.setState(stateJson);
        this.selectedrowid = row.id;
        let url = API_URL +'genomics/searchVariants';
        axios.post(url, {"positions": row.Position, "vcffile": this.selectedVCFfilename, "searchtype":"detail"})
            .then(response => {
                if(response.data.length > 0){
                    var detailList = response.data[0].DetailList;
                    var refallele = JSON.parse(detailList).filter(function (entry: VariantDetail) {
                        return entry.Genotype === '0/0';
                    });
            
                    var altallele = JSON.parse(detailList).filter(function (entry: VariantDetail) {
                        return entry.Genotype === '1/1';
                    });
                    
                    var ref_Ids = refallele.map(function(obj: VariantDetail){
                        return obj.SampleId; 
                    });
                    
                    var alt_Ids = altallele.map(function(obj: VariantDetail){
                        return obj.SampleId; 
                    });
                    
                    var VSList1_name = "VS-" + row.Position + "-" + row.RefAllele.substring(0,10);
                    var VSList2_name = "VS-" + row.Position + "-" + row.AltAllele.substring(0,10);
                    
                    if(row.GeneName != null){
                        var gn = row.GeneName.split('&')[0];
                        VSList1_name = VSList1_name + "-" + gn + "-" + row.AAChange;
                        VSList2_name = VSList2_name + "-" + gn + "-" + row.AAChange;
                    }

                    var selectedvcfs = Array<VCF>();
                    var selectedvcffile = JSON.parse(this.state.selectedvcffile) as VCF;
                    selectedvcfs.push(selectedvcffile);
                    var vcflistStr = JSON.stringify(selectedvcfs);

                    let url = API_URL +'listmanagement/createVariantList';
                    axios.post(url, {"ref_sampleIds": ref_Ids, 
                                     "alt_sampleIds": alt_Ids, 
                                     "listtype": "Sample",
                                     "vcfList": vcflistStr,
                                     "listsource": "VariantSearch",
                                     "vsList1": VSList1_name, 
                                     "vsList2": VSList2_name})
                        .then(response => {
                            var returnval = response.data;
                            stateJson.errors = returnval.message;
                            
                            var displayresults = this.state.displayResults;

                            var index = displayresults.findIndex((e) => e.id === row.id);
                            var oldrowdata = displayresults[index];
                            var newrowdata = JSON.parse(JSON.stringify(oldrowdata));
                            newrowdata.VSList= returnval.VSListName;
                            var newresult = updateTableRow(displayresults, index, newrowdata);
                            stateJson.displayResults = newresult;

                            /* var cohortName = returnval.cohortname;
                            var failedCohorts = returnval.failedCohorts;
                            var strn1 = cohortName.search(cohort1_name);
                            var strn2 = cohortName.search(cohort2_name);

                            if(strn1 >=0 || strn2 >=0){
                                var displayresults = this.state.displayResults;
                                var index = displayresults.findIndex((e) => e.id === row.id);
                                var oldrowdata = displayresults[index];
                                var newrowdata = JSON.parse(JSON.stringify(oldrowdata));
                                newrowdata.cohort= cohortName;
                                var newresult = updateTableRow(displayresults, index, newrowdata);
                                stateJson.displayResults = newresult;

                                var message = "";
                                for (var i=0; i < failedCohorts.length; i++) {
                                    var failednumber = failedCohorts[i].failedSSRIDsNumber;
                                    var cohortname = failedCohorts[i].cohortName;
                                    if(message !== "")  message = message + " \n";
                                    message =  message +  "Cohorts " + cohortname + " created failed or sucessful with error. " + "Failed SSRIds number:" + failednumber;
                                }

                                stateJson.errors = message;
		        	        }
                            else{
                                stateJson.errors = "Cohorts created failed. Please contact GAP support.";
                            } */
                            stateJson.blocking=false;
                            this.setState(stateJson);
                        })
                        .catch(error => {
                            console.error('There was an error!' + error.message);
                            stateJson.blocking = false;
                            stateJson.errors = error.message;
                            this.setState(stateJson);
                        });
                }
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.blocking = false;
                stateJson.errors = error.message;
                this.setState(stateJson);
            });
    }

    changesearchtype =(stateStatus: VariantSearchState) =>{
       
		switch(stateStatus.seachbyname) {
			case "R":
	            stateStatus.placeholdname="RefseqID name";
	    		break;
	    	case "P":
                stateStatus.placeholdname="Protein name";
	    		break;
	    	case "G":
                stateStatus.placeholdname="Gene name";
				break;
			case "V":
                stateStatus.placeholdname="Variant List";
				break;
	    	default:
	    		stateStatus.placeholdname="";
		}

        if (stateStatus.seachbyname == 'V') {
            var newvariantlist = [] as SearchableListData[];
            this.variantList.forEach(function (list) {
                var item = {
                    "value": list.listname,
                    "label": list.totallistcontent
                } as SearchableListData;
                newvariantlist.push(item);
            });

            this.genenames = newvariantlist;
		}
		else if(stateStatus.seachbyname !='N'){
            var searchbyArray = this.searchNameArray[stateStatus.seachbyname];
		    this.genenames = Object.entries(searchbyArray).map(([key, value], i) => {
                return { "label":value.Name,  "value": JSON.stringify(value)}
            });
		}

        return stateStatus;
    }

    searchVCF =() =>{
        if(this.state.seachbyname == "N"){
            var rangefrom = parseInt(this.state.rangefrom );
            var rangeto = parseInt(this.state.rangeto);
            if(!isNaN(rangeto) && rangeto < rangefrom){
                this.setState({ errors: "Pleae enter a Range!"});
			    return;
            }
		}
      
        var stateJson = {
            blocking:true,
            resultfound: false,
            displaydetail:false,
            impacts: Array<impactType>(),
            errors:""
        } as VariantSearchState;

        var selectedvcffile = JSON.parse(this.state.selectedvcffile) as VCF;
        var vcffilename = selectedvcffile.filename;
		if(selectedvcffile.owner!=null && selectedvcffile.owner !="system"){
			vcffilename = selectedvcffile.owner + "/" + vcffilename;
		}
		
		var genename = "";
		var positions = "";
		var variantStr = "";	

        if(this.state.seachbyname != "N"){
            if(this.state.selectedGeneName != undefined){
                var selectedgenename = JSON.parse(this.state.selectedGeneName.value) as GenePosition;
                genename = selectedgenename.Name;
                positions = selectedgenename.Positions;
            }
		}
		else{  //Range
			var range_value = parseInt(this.state.rangeto) - parseInt(this.state.rangefrom);
			if(range_value > this.usersession.max_search_range){
				alert("Search Range is great than " + this.usersession.max_search_range.toString());
				return;
			}
			positions = this.state.rangefrom.toString();
			if(this.state.rangeto != null){
				positions = positions + "-" + this.state.rangeto.toString();
			}
		}

        this.setState(stateJson);
        let url = API_URL +'genomics/searchVariants';
        axios.post(url, {"genename": genename, "positions": positions, "variants": variantStr, "vcffile":vcffilename})
            .then(response => {
                if(response.data.length > 0){
                    var varantdata = response.data as Variant[];
        			this.variantResults =this.PrepareData(varantdata);
                    stateJson.displayResults = this.variantResults;
        			stateJson.resultfound = true;
        		}
        		else{
        			stateJson.errors="No data Found";
        		}
                stateJson.blocking=false;
                this.setState(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.errors = error.message;
                stateJson.blocking=false;
                stateJson.resultfound = false;
                this.setState(stateJson);
            });
	}

    PrepareData=(data: Variant[]) => {
        var bardata =Array<number>();
        this.barchartdata.datasets=[];
        this.barchartdata.labels=[];
	    var countArray: { [key: string]: number }={};
		var newdata = [] as Variant[];

		for (var i = 0; i < data.length; i++) {
			var olddata = data[i];
			
			olddata.RefCount=parseFloat(olddata.RefCount.toString());
			olddata.AltCount=parseFloat(olddata.AltCount.toString());
			olddata.MissingCount=parseFloat(olddata.MissingCount.toString());

            olddata.AltAllele_display = olddata.AltAllele;
			if(olddata.hasOwnProperty("AltAllele") && olddata.AltAllele.length > 20){
				olddata.AltAllele_display = olddata.AltAllele.substring(0,10) + "....";
			}
			olddata.RefAllele_display = olddata.RefAllele;
			if(olddata.hasOwnProperty("RefAllele") && olddata.RefAllele.length > 20){
    		    olddata.RefAllele_display = olddata.RefAllele.substring(0,10) + "....";
			}
			olddata.AAChange_display = olddata.AAChange;
			if(olddata.hasOwnProperty("AAChange") && olddata.AAChange.length > 20){
    		    olddata.AAChange_display = olddata.AAChange.substring(0,10) + "....";
			}
			olddata.NTChange_display = olddata.NTChange
			if(olddata.hasOwnProperty("NTChange") && olddata.NTChange.length > 20){
    		    olddata.NTChange_display = olddata.NTChange.substring(0,10) + "....";
			}
            olddata.GeneName_display = olddata.GeneName
			if(olddata.hasOwnProperty("GeneName") && olddata.GeneName.length > 20){
    		    olddata.GeneName_display = olddata.GeneName.substring(0,10) + "....";
			}
            olddata.GeneID_display = olddata.GeneID
			if(olddata.hasOwnProperty("GeneID") && olddata.GeneID.length > 20){
    		    olddata.GeneID_display = olddata.GeneID.substring(0,10) + "....";
			}
		
			newdata.push(olddata);
			//Setup Bar Chart
			var imapctVal = olddata.Impact;
			if (imapctVal == undefined || imapctVal == null || imapctVal.length == 0) imapctVal="Other";
			if(imapctVal.includes("&")){
				var combineimpacts = imapctVal.split("&");
				for (var j = 0; j < combineimpacts.length; j++) {
					var im = combineimpacts[j];
					if (countArray.hasOwnProperty(im)) {
						countArray[im]++;
					 }
					 else {
						   countArray[im] = 1;
					}
				}
			}
			else{
				if (countArray.hasOwnProperty(imapctVal)) {
					countArray[imapctVal]++;
				 }
				 else {
					   countArray[imapctVal] = 1;
				}
			}
		};
	
		for (var i = 0; i < countArray.length; i++) {
			bardata.push(countArray[i]);
		}
		
		for (var prop in countArray) {
            if (countArray.hasOwnProperty(prop)) {
                bardata.push(countArray[prop]);
                this.barchartdata.labels.push(prop);
                var filter = { 'id': prop, 'name': prop, 'selected': false };
                this.state.impacts.push(filter);
            }
		}

        var barchartdata = {
            label: '',
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: bardata
        }

        this.barchartdata.datasets.push(barchartdata); 
        return newdata;
	};
	

    getGeneNames= (type:string, pre_selected:string, stateJson: VariantSearchState) =>{
        let selectedvcf  = JSON.parse(stateJson.selectedvcffile) as VCF;
		var vcffilename = selectedvcf.filename;
		if(selectedvcf.owner!=null && selectedvcf.owner !="system"){
			vcffilename = selectedvcf.owner + "/" + vcffilename;
		}

		//using cvf file to find paired ann file.
        let url = API_URL +'genomics/getGeneNames';
        axios.post(url, {"vcffile": vcffilename, "vcfid": selectedvcf.vcfid})
            .then(response => {
                this.searchNameArray = response.data;
                stateJson.seachbyname=type;
        		stateJson = this.changesearchtype(stateJson);
        		
        		if(pre_selected != null && pre_selected != ''){
        			var found = this.genenames.find(function(genename){
        				return genename.label == pre_selected;
        			});
        			
        			if(found != undefined){
                        stateJson.selectedGeneName = found;
                        this.setState(stateJson);
        				this.searchVCF();
        			}
        			else {
        				stateJson.errors = "No data found for term " + pre_selected;
        			}
        		}

                stateJson.blocking=false;
                this.setState(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.errors = error.message;
                this.setState(stateJson);
            });
	}

    opendetail =(currentrow:Variant, type:string) => {
        this.selectedrowid = currentrow.id;
        var position = currentrow.Position;
        var reloadSview = this.state.reloadSview + 1;
        var stateJson = {
            blocking:true,
            displaydetail:false,
            reloadSview: reloadSview,
            displaySviewPosition: position.toString(),
            errors:""
        } as VariantSearchState;

        var selectedvcffile = JSON.parse(this.state.selectedvcffile) as VCF;
        var vcffilename = selectedvcffile.filename;
		if(selectedvcffile.owner!=null && selectedvcffile.owner !="system"){
			vcffilename = selectedvcffile.owner + "/" + vcffilename;
		}

        this.setState(stateJson);
        let url = API_URL +'genomics/searchVariants';
        axios.post(url, {"positions": position, "vcffile":vcffilename, "searchtype":"detail"})
            .then(response => {
                if(response.data.length > 0){
                    var detailList = response.data[0].DetailList;
                    var detailJson = JSON.parse(detailList);
                    if(type != 'all'){
                        detailJson = JSON.parse(detailList).filter(function (entry: VariantDetail) {
                            if(type =="."){
                                return (entry.Genotype === type || entry.Genotype === "./.");
                            }
                            else{
                                return entry.Genotype === type;
                            }
                        });
                    }

                    this.variantDetail = detailJson;
                    stateJson.displaydetail = true;
        		}
        		else{
        			stateJson.errors="No data Found";
        		}
                stateJson.blocking=false;
                this.setState(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.errors = error.message;
                stateJson.blocking=false;
                stateJson.resultfound = false;
                this.setState(stateJson);
            });
    }

    onChangeGeneName =(option: SingleValue<SearchableListData>) => {
		if(option == null){
			this.setState({selectedGeneName: undefined});
		}
		else{
        	this.setState({selectedGeneName: {value:option?.value, label:option?.label}});
		}
    }

    initialCompont = () => {
        this.setState({blocking:true});
        const queries = queryString.parse(location.search);
        if (queries.term != undefined) {   //Call from third party
            //var param_vcf = queries.vcf.toString();
            var param_RefseqID = queries.term.toString();
            this.loadNewSession(param_RefseqID);
        }
        else {
            this.loadReferences("");
        }
    }

    onChangevcf = (event: React.FormEvent<HTMLSelectElement>) => {
        var target = event.target as HTMLSelectElement;
        var selectedvcf = JSON.parse (target.value);
        var ncbiref = this.NCBIs[selectedvcf.reference].NCBI_Embedded;
        var reloadSview = this.state.reloadSview + 1;
        var stateJson = {
                selectedvcffile: target.value, 
                selectedncbivalue: ncbiref, 
                ncbiName: selectedvcf.reference,
                reloadSview: reloadSview,
                resultfound: false,
			    displaydetail: false,
        } as VariantSearchState

		var vcffilename = selectedvcf.filename;
		if (selectedvcf.owner != null && selectedvcf.owner != "system") {
			vcffilename = selectedvcf.owner + "/" + vcffilename;
		}
		this.selectedVCFfilename = vcffilename;

        this.setState({blocking:true});
        this.getGeneNames("G", "", stateJson);
    }

    componentDidMount(){
        this.initialCompont();
    }

    render(){
        removeOrcidCode("VariantSearch", this.props);
        this.usersession = JSON.parse(sessionStorage.userSession);
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />}>
                    <div className="gap-page-content">
                        <div className="container">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className='breadcrumb-item'>
                                        <UISref to="home"><a>Home</a></UISref>
                                    </li>
                                    <li className='breadcrumb-item'>
                                        <UISref to="variants"><a>Variant</a></UISref>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Variant Search</li>
                                </ol>
                            </nav>

                            <div className="row">
                                <span className="redcolor">{this.state.errors}</span>
                            </div>

                            <h1>Variant Search and Cohort Creation Using Genomic Information</h1>
                            <p>Search for genomic variants using a gene name, protein or refseq identifier, or range on the genome.
                            </p>
                            <hr />
                            <form name="vcfForm">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="vcffile">VCF File</label>
                                        <div className="form-group">
                                            <select
                                                className="form-select"
                                                value={this.state.selectedvcffile}
                                                onChange={this.onChangevcf}>
                                                {
                                                    Object.entries(this.vcffiles).map(([key, value], i) => (
                                                        <option key={key} value={JSON.stringify(value)}>{value.displayname}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row margin-top-15">
                                    <div className="col-md-8">
                                        <label>Search By: &nbsp;</label>
                                        <input id="genename" type="radio" name="seachbyname" value="G" checked={this.state.seachbyname == "G"} onChange={this.searchby} /><label className="form-label fw-bold" htmlFor="genename">&nbsp;Gene Name&nbsp;&nbsp;</label>
                                        <input id="RefseqID" type="radio" name="seachbyname" value="R" checked={this.state.seachbyname == "R"} onChange={this.searchby} /><label className="form-label fw-bold" htmlFor="RefseqID">&nbsp;Gene ID&nbsp;&nbsp;</label>
                                        <input id="protein" type="radio" name="seachbyname" value="P" checked={this.state.seachbyname == "P"} onChange={this.searchby} /><label className="form-label fw-bold" htmlFor="protein" >&nbsp;Protein&nbsp;&nbsp;</label>
                                        <input id="Range" type="radio" name="seachbyname" value="N" checked={this.state.seachbyname == "N"} onChange={this.searchby} /><label className="form-label fw-bold" htmlFor="Range">&nbsp;Range&nbsp;&nbsp;</label>
                                    </div>

                                    {this.state.seachbyname != 'N' &&
                                        <div className="col-md-6 form-group" >
                                            <Select className="searchableLay"
                                                inputId="geneid"
                                                options={this.genenames}
                                                value={this.genenames.filter(({ value }) => value === this.state.selectedGeneName?.value)}
                                                onChange={(option) => this.onChangeGeneName(option)}
                                                isSearchable={true}
                                                isClearable={true}
                                                filterOption={createFilter({ ignoreAccents: false })}
                                                placeholder={"Select " + this.state.placeholdname + " ..."} />
                                        </div>
                                    }
                                    {this.state.seachbyname == 'N' &&
                                        <div className="col-md-6" >
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="rangefrom">From</label>
                                                    <input type="text" pattern="[0-9]*" className="form-control" id="rangefrom" value={this.state.rangefrom} onChange={this.changeRangeFrom} />
                                                </div>

                                                <div className="col-md-6">
                                                    <label htmlFor="rangeto">To</label>
                                                    <input type="text" pattern="[0-9]*" className="form-control" id="rangeto" value={this.state.rangeto} name="rangeto" onChange={this.changeRangeTo} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </form>
                            <br></br>
                            <div className="margin-bottom-20">
                                <button id="search" type="button"
                                    disabled={this.state.selectedGeneName == undefined && this.state.seachbyname != 'N' ||
                                        this.state.seachbyname == 'N' && this.state.rangefrom == ""}
                                    className="btn btn-primary" onClick={this.searchVCF}>Search
                                </button>
                            </div>
                            
                            {this.state.resultfound &&
                                <div className="panel-body">
                                    <label className='total_variant_found'> Total Variants Found:
                                        {this.variantResults.length}</label>
                                    <div style={{ width: '500px' }}>
                                        <Bar data={this.barchartdata} options={barchartoption} />
                                    </div>
                                    <hr />

                                    <div className="row margin-bottom-5">
                                        <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                            <CSVLink data={this.state.displayResults} className="btn btn-success"
                                                filename={"VariantSearch.csv"}
                                                headers={[
                                                    { label: "Position", key: "Position" },
                                                    { label: "Ref Allele", key: "RefAllele" },
                                                    { label: "Ref Count", key: "RefCount" },
                                                    { label: "Alt Allele", key: "AltAllele" },
                                                    { label: "Alt Count", key: "AltCount" },
                                                    { label: "Missing Count", key: "MissingCount" },
                                                    { label: "Impact", key: "Impact" },
                                                    { label: "AA Change", key: "AAChange" },
                                                    { label: "Gene Name", key: "GeneName" },
                                                    { label: "Gene ID", key: "GeneID" },
                                                    { label: "Protein ID", key: "ProteinID" },
                                                    { label: "Protein Name", key: "ProteinName" },
                                                ]}>
                                                <span className="glyphicon glyphicon-save"></span>Download
                                            </CSVLink>
                                        </div>

                                        <div className="col-md-10 col-lg-10 col-sm-10 col-xs-10" >
                                            {this.state.impacts.map((item, index) => (
                                                <div className="impact" key={index}>
                                                    <input
                                                        id={item.id}
                                                        type="checkbox"
                                                        name={item.name}
                                                        value={item.name}
                                                        checked={item.selected}
                                                        onChange={this.impactChecked}
                                                    />
                                                    <label htmlFor={item.id}>&nbsp;{item.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <VariantTable searchResults={this.state.displayResults}
                                            isverified={this.usersession.isverified}
                                            opendetail={(currentrow: Variant, type: string) => this.opendetail(currentrow, type)}
                                            selectedrowid={this.selectedrowid}
                                            createVSList={(row: Variant) => this.createVSList(row)} />
                                    </div>
                                </div>
                            }
                            
                            <NcbiSViewer NCBIEmbeddedParameter={this.state.selectedncbivalue} trackurl='' reloadSview={this.state.reloadSview} position={this.state.displaySviewPosition} />
                        
                            {this.state.displaydetail &&
                                <div >
                                    <div className="margin-top-30 margin-bottom-10">
                                        <CSVLink filename={"Searchdetail.csv"} data={this.variantDetail} className=" btn btn-success"
                                            headers={[
                                                { label: "Sample ID", key: "SampleId" },
                                                { label: "Position", key: "Position" },
                                                { label: "Ref Allele", key: "RefAllele" },
                                                { label: "Alt Allele", key: "AltAllele" },
                                                { label: "Gene Name", key: "GeneName" },
                                                { label: "AA Change", key: "AAChange" },
                                                { label: "Genotype", key: "Genotype" },
                                            ]}>
                                            <span className="glyphicon glyphicon-save"></span>Download
                                        </CSVLink>
                                    </div>
                                    <GAPSimpleTable tableResults={this.variantDetail} columns={VariantDetailColumns} defaultSorted={defaultSorted} />
                                </div>
                            }
                        </div>
                    </div>
                </BlockUi>
            </div>
        )
    };
};
