
import React from "react";
import parse from 'html-react-parser';
import BlockUi from 'react-block-ui';
import { Dna } from  'react-loader-spinner';
import axios from "axios";
import { ProgressBar } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { UISref, Transition} from "@uirouter/react";
import Select, {SingleValue, createFilter} from 'react-select';
import VCF from '../../../domain/VCF'
import API_URL from '../../../services/Enviroment';
import UserSession from '../../../services/UserSession';
import UserGroup from "../../../domain/UserGroup";
import UserGroupTable from '../UserProfileTable';
import SampleListTable from './SampleListTable';
import DualListBox from 'react-dual-listbox';
import User from "../../../domain/User";
import './ListManagement.css'
import SampleList from "../../../domain/SampleList";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Cohort from "../../../domain/Cohort";
import SearchableListData from "../../../domain/SearchableListData"

type listState = {
    blocking: boolean,
    modal_blocking : boolean,
    blockmessage: string,
    selectedCohort?: SearchableListData,
    selectedSample?: SearchableListData,
    ListType: string,
    ListName: string,
    cohortListName: string,
    listCohortName: string,
    selectedgroupid: number | undefined,
    activeModelKey: ActiveModelTypes,
    SelectedListRow?: SampleList,
    selectedRows:  Array<String>,
    listFile?: File,
    progress1: number,
    showModal: boolean,
    isModalEdit: boolean,
    haserror: boolean,
    errors: string
    hasModalerror: boolean,
    Modalerrors: string,
    totalDualListSampleids: SearchableListData[],
	selectedDualListSampleids: string[],
    modalVcffiles: Array<VCF>;
    newSample: string;
}

var ListTypes=[
    'Gene',
    'Sample',
    //'Variant'
]

export enum ActiveModelTypes{
    Merge,
    Upload,
    CohortList,
    SampleCohort,
    Edit
 }

export default class ListManagement extends React.Component <Transition, listState> {
    usersession = UserSession.getUserSession();
    allGroups =  Array<UserGroup>();
    allUsers =  Array<User>();
    alllist = Array<SampleList>();
    isAdminGroup = false;
    selecteduserGroups=new Array();
    vcffiles = Array<VCF>();
    //modalVcffiles = Array<VCF>();
    oldlistname = "";
    selectedRowid="";
    nonSelectedRowIds=Array<String>();
    searchablecohorts = Array<SearchableListData>();
    searchablesamples = Array<SearchableListData>();

    constructor(props: Transition) {
        super(props);
        if (sessionStorage.token == null || sessionStorage.token == "") {
            let transition = props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.usersession = JSON.parse(sessionStorage.userSession);
        if(!this.usersession.isadminuser){
            let transition = props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.state = {
            blocking: false,
            modal_blocking: false,
            blockmessage: "Loading",
            ListType: "",
            ListName: "",
            cohortListName: "",
            listCohortName: "",
            activeModelKey: ActiveModelTypes.Upload,
            selectedgroupid: undefined,
            //saveButtonText: "Upload Sample List",
            SelectedListRow: undefined,
            selectedRows:[],
            //SelectedVCF:[],
            selectedCohort: undefined,
            selectedSample: undefined,
            listFile: undefined,
            progress1: 0,
            isModalEdit: false,
            showModal: false,
            haserror: false,
            errors: "",
            hasModalerror:false,
            Modalerrors:"",
            totalDualListSampleids: [],
	        selectedDualListSampleids: [],
            modalVcffiles: [],
            newSample:""
        };
    }

    loadList = (stateJson: listState) => {
        let url = API_URL +'listmanagement/getList';
        axios.get(url, {params: {isuserlist: true, isAdmin:true}})
            .then(response => {
                this.alllist = response.data;
               
                this.searchablesamples = Array<SearchableListData>();
                for (var i = 0; i < this.alllist.length; i++) {
                    var sample = this.alllist[i] as SampleList;
                    this.searchablesamples.push({label: sample.listname, value: sample.listid});
                };

                stateJson.blocking = false;
                this.setState(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({blocking: false});
            });
	}

    loadvcffile = (stateJson: listState) => {
        let url = API_URL +'genomics/VCFfiles';
        axios.post(url, { type: 'vcf'})
            .then(response => {
                this.vcffiles = response.data;
                this.loadList(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({blocking: false});
            });
    }

    loadusergroup =(stateJson: listState) =>{
        let url = API_URL +'user/getUserGroups';
        axios.post(url, {isadmin: this.usersession.isadminuser, email: this.usersession.email})
            .then((response) => {
                if(response.status == 200 ){
                    this.allGroups = response.data;
                    this.loadvcffile(stateJson);
                }
            })
            .catch((error) => {
                console.error("There was an error!" + error.message);
                stateJson.blocking = false;
                this.setState(stateJson);
            });
    }

    onChangeListType = (event: React.FormEvent<HTMLSelectElement>) => {
        var target = event.target as HTMLSelectElement;
        var selectedValue = target.value;
        this.state.modalVcffiles.forEach((vcf) => {
            vcf.selected = false;
        });

        this.setState ({ListType: selectedValue,
                        ListName: "",
                        selectedgroupid: undefined,
                        });
	}

    onEnterListName = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState ({ListName:  target.value});
    }

    onListFileChange = (event: React.ChangeEvent <HTMLInputElement>) => {
        const fileList = event.target.files;
        if (!fileList) return;
        this.setState({listFile: fileList[0]})
    }

    onCohortName = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState ({listCohortName:  target.value});
    }

    onCohortListName = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState ({cohortListName:  target.value});
    }

    onSelectVCFChange = (event: React.ChangeEvent <HTMLInputElement>) => {
        const { value, checked } = event.target;
        var selectedvcf = JSON.parse(value) as VCF;

        var modalvcfs = this.state.modalVcffiles;
        var vcf = modalvcfs.find(x=>x.vcfid == selectedvcf.vcfid) as VCF;
        vcf.selected = checked;
        
        this.setState({modalVcffiles: modalvcfs});
    }

    onSelectUserGroups =(selectedRows:[]) =>{   
        this.selecteduserGroups = selectedRows;
    }

    RefreshUserData =() =>{
        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            errors:"",
            showModal: false,
        } as listState;
        this.setState(stateJson);

        this.loadusergroup(stateJson);
    }
    
    onSaveSampleList = () => {
        var stateJson = {
            blocking: false,
            modal_blocking: true,
            hasModalerror: false,
            Modalerrors: "",
        } as listState;
        this.setState(stateJson);

        switch (this.state.activeModelKey) {
            case ActiveModelTypes.Edit:
                return this.updateSampleList(stateJson);
            case ActiveModelTypes.Merge:
                return this.saveMergeSampleList(stateJson);
            case ActiveModelTypes.Upload:
                return this.saveUploadSampleList(stateJson);
            case ActiveModelTypes.CohortList:
                return this.saveCohortSampleList(stateJson);
            case ActiveModelTypes.SampleCohort:
                return this.createSampleCohorts(stateJson);
        }
    }

    //Edit
    updateSampleList = (stateJson:listState) => {
        var selectedvcfs = Array<VCF>();
        if(this.state.ListType == "Sample"){
            this.state.modalVcffiles.forEach((vcf) => {
                if(vcf.selected){
                    selectedvcfs.push(vcf);
                }
              });
		}
        var vcflistStr = JSON.stringify(selectedvcfs);

        var selectedGroupIds: { [key: string]: boolean } = {};
        this.selecteduserGroups.forEach(groupid=>{
            selectedGroupIds[groupid] =true;
        })

        var listcontent = this.state.selectedDualListSampleids.join("\n");

        let url = API_URL + 'listmanagement/updateList';
        axios.post(url, { 
            listid: this.state.SelectedListRow?.listid,
            oldlistname: this.oldlistname,
            newlistname: this.state.ListName,
		    vcfList: vcflistStr,
            listcontent: listcontent,
            groupsIds: JSON.stringify(selectedGroupIds)
        }) 
        .then(response => {
            if(response.status == 200){
                var response_data = response.data;
				var errormessage = "";
				for (var i = 0; i < response_data.length; i++) {
                    var vcf_result = response_data[i];
                    if (!vcf_result.status) {
                        var v = this.state.modalVcffiles.find(x => x.vcfid == vcf_result.vcfid) as VCF;
                        v.selected = false;

                        stateJson.hasModalerror = true;
                        errormessage = errormessage + "Error: the selected VCF file:" + vcf_result.vcf + " have no samples.<br>" ;
                    }
                }

				if(stateJson.hasModalerror){
                    stateJson.Modalerrors = errormessage;
                    stateJson.modal_blocking = false;
				}
				else{
                    stateJson.modal_blocking = false;
                    stateJson.showModal = false;
				}
                this.loadList(stateJson);
            }
            else{
                stateJson.modal_blocking = false;
                stateJson.hasModalerror = true;
                stateJson.Modalerrors = response.data;	
                this.setState( stateJson );
            }
        })
        .catch(error => {
            stateJson.modal_blocking = false;
            console.error('There was an error!' + error.message);
            if(error.response.status == 400){
                stateJson.Modalerrors = error.response.data;	
                stateJson.hasModalerror=true;
                this.setState( stateJson );
            }
            else{
                stateJson.Modalerrors='There was an error!' + error.message;
                stateJson.hasModalerror=true;
                this.setState( stateJson );
            }
        });
    }

    //Merge Sample List
    saveMergeSampleList = (stateJson:listState) => {
        var selectedvcfs = Array<VCF>();
        if(this.state.ListType == "Sample"){
            this.state.modalVcffiles.forEach((vcf) => {
                if(vcf.selected){
                    selectedvcfs.push(vcf);
                }
              });
		}
        var vcflistStr = JSON.stringify(selectedvcfs);

        var selectedGroupIds: { [key: string]: boolean } = {};
        this.selecteduserGroups.forEach(groupid=>{
            selectedGroupIds[groupid] =true;
        })

        var listcontent = this.state.selectedDualListSampleids.join("\n");

        this.saveSampleList(stateJson, this.state.ListName, listcontent, "Intersection", "Sample", vcflistStr, selectedGroupIds);
    }

    //Upload Sample List
    saveUploadSampleList = (stateJson:listState) => {
        if(this.state.listFile == undefined){
            stateJson.blocking = false;
            this.setState (stateJson);
            return;
        }
    
        var selectedvcfs = Array<VCF>();
        if(this.state.ListType == "Sample"){
            this.state.modalVcffiles.forEach((vcf) => {
                if(vcf.selected){
                    selectedvcfs.push(vcf);
                }
              });
		}
        var vcflistStr = JSON.stringify(selectedvcfs);

        var selectedGroupIds: { [key: string]: boolean } = {};
        this.selecteduserGroups.forEach(groupid=>{
            selectedGroupIds[groupid] =true;
        })

        let url = API_URL + 'listmanagement/uploadlistFile';
        let formData = new FormData()
        formData.append("file", this.state.listFile);
        formData.append("listname", this.state.ListName);
        formData.append("vcfList", vcflistStr);
        formData.append("listsource", "Upload");
        formData.append("listtype", this.state.ListType);
        formData.append("groupsIds", JSON.stringify(selectedGroupIds));

        axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
                this.setState({ progress1: (Math.round((100 * data.loaded) / data.total)) });
            },
        })
        .then(response => {
            var response_data = response.data;
            var errormessage = "";

            if(response_data.hasOwnProperty("Status")){
                errormessage = response_data.message;
                stateJson.hasModalerror = true;
            }
            else{
                for (var i = 0; i < response_data.length; i++) {
                    var vcf_result = response_data[i];
                    
                    if (!vcf_result.status) {
                        var v = this.state.modalVcffiles.find(x => x.vcfid == vcf_result.vcfid) as VCF;
                        v.selected = false;

                        stateJson.hasModalerror = true;
                        errormessage = errormessage + "Error: the selected VCF file:" + vcf_result.vcf + " have no samples.<br>" ;
                    }
                }
            }

            if(!stateJson.hasModalerror){
                errormessage = "User sample list upload successfully.";
            }

            stateJson.modal_blocking=false;
            stateJson.Modalerrors=errormessage;
            stateJson.hasModalerror=true;

            this.loadList(stateJson);
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            stateJson.modal_blocking=false;
            stateJson.Modalerrors=error.response.data;
            stateJson.hasModalerror=true;
            this.setState( stateJson );
        });
    }

    saveCohortSampleList = (stateJson:listState) => {
        var selectedGroupIds: { [key: string]: boolean } = {};
        this.selecteduserGroups.forEach(groupid=>{
            selectedGroupIds[groupid] =true;
        })

        var selectedvcfs = Array<VCF>();
        if(this.state.ListType == "Sample"){
            this.state.modalVcffiles.forEach((vcf) => {
                if(vcf.selected){
                    selectedvcfs.push(vcf);
                }
              });
		}
        var vcflistStr = JSON.stringify(selectedvcfs);

        var cohortlist = this.usersession.Depot_cohorts as Cohort[];
        var selectedcohort = this.state.selectedCohort as SearchableListData;
        var cohort_content = (cohortlist.find(x=>x.name == selectedcohort.label)as Cohort).ssrids;
         
		var listcontent = cohort_content.toString();
		listcontent = listcontent.replace(/,/g, '\n');

        this.saveSampleList(stateJson, "Cohort-" + this.state.cohortListName, listcontent, "Cohort", "Sample", vcflistStr, selectedGroupIds);
       
    }

    saveSampleList = (stateJson:listState, 
        listName: String, 
        listcontent: String, 
        listsource: String,
        listtype: String,
        vcflistStr: String,
        selectedGroupIds: { [key: string]: boolean } 
        ) => {
        let url = API_URL + 'listmanagement/saveList';
        axios.post(url, { 
            listcontent: listcontent,
            listName: listName,
            listtype: listtype,
			listsource: listsource,
			vcfList: vcflistStr,
            groupsIds: JSON.stringify(selectedGroupIds)
        }) 
        .then(response => {
            var response_data = response.data;
            var errormessage = "";
            if(response_data.hasOwnProperty("Status")){
                errormessage = response_data.message;
                stateJson.hasModalerror = true;
            }
            else{
                for (var i = 0; i < response_data.length; i++) {
                    var vcf_result = response_data[i];
                    
                    if (!vcf_result.status) {
                        var v = this.state.modalVcffiles.find(x => x.vcfid == vcf_result.vcfid) as VCF;
                        v.selected = false;

                        stateJson.hasModalerror = true;
                        errormessage = errormessage + "Error: the selected VCF file:" + vcf_result.vcf + " have no samples.<br>" ;
                    }
                }
            }

            if(stateJson.hasModalerror){
                stateJson.Modalerrors = errormessage;
                stateJson.modal_blocking = false;
            }
            else{
                stateJson.modal_blocking = false;
                stateJson.showModal = false;
            }

            // stateJson.selectedCohort = undefined;
            // stateJson.cohortListName = "";
            // stateJson.modal_blocking = false;
            // stateJson.Modalerrors = errormessage;

            this.loadList(stateJson);
        })
        .catch(error => {
            stateJson.hasModalerror = true;
            console.error('There was an error!' + error.message);
            stateJson.modal_blocking=false;
            stateJson.Modalerrors='There was an error!' + error.message;
            this.setState( stateJson );
        });
    }

    createSampleCohorts =(stateJson:listState)  =>{
        let url = API_URL +'listmanagement/createSampleCohort';
        var depot_cohortname = "GAP-"+ this.state.listCohortName + "-" + new Date().toISOString().slice(0, 10);

        axios.post(url, { "samplelistid": this.state.selectedSample?.value, "cohortName": depot_cohortname })
            .then(response => {
                var returnval = response.data;
                var errormessage= "";
                if(returnval.hasOwnProperty("depotmessage")){
                    errormessage = returnval.depotmessage;
                }
                else{
                    var cohortName = returnval.cohortname;
                    var failedSSRIDsNumber = returnval.failedSSRIDsNumber;
                    
                    if(cohortName != ""){   //Cohort created sucessfully.
                        if(failedSSRIDsNumber > 0){
                            errormessage = "Partial sample cohorts " + depot_cohortname + " has been created and " + "failed SSRIds number:" + failedSSRIDsNumber;
                        }
                        else {
                            errormessage = "Cohorts " + depot_cohortname + " has been created sucessful.";
                        }

                        this.usersession.Depot_cohorts = returnval.Depot_cohorts;
	        	        sessionStorage.setItem("userSession", JSON.stringify(this.usersession));
                    }
                    else {
                        errormessage = "Created cohorts " + depot_cohortname + " failed with error.  Total failed ssids: " + failedSSRIDsNumber;
                    }
                }
                stateJson.blocking=false;
                stateJson.modal_blocking = false;
                stateJson.hasModalerror = true;
                stateJson.Modalerrors = errormessage;
                this.setState(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.modal_blocking  = false;
                if(error.response.status == 400){   //Bad request
                    stateJson.Modalerrors = error.response.data;
                }
                else{
                    stateJson.Modalerrors='There was an error!' + error.message;
                 }
                stateJson.hasModalerror=true;
                this.setState( stateJson );
            });
    }

    onReloadCohorts =() => {   //will add a call to get latest cohorts from DEPOT.
		if(this.usersession.isverified){
            this.setState({modal_blocking: true}); 
            let url = API_URL +'genomics/reloadDepotData';
            axios.post(url, {email:this.usersession.email})
                .then(response => {
                    this.usersession.Depot_cohorts = response.data;
	        	    sessionStorage.setItem("userSession", JSON.stringify(this.usersession));
                    this.genSearchableCohort();
                    this.setState({modal_blocking: false}); 
                })
                .catch(error => {
                    console.error('There was an error!' + error.message);
                    this.setState ({errors: 'There was an error!' + error.message, blocking: false});
                });
		}
	}

    onChangeCohort=(option: SingleValue<SearchableListData>) => {
        if(option == null){
			this.setState({selectedCohort: undefined, cohortListName: ""});
		}
		else{
        	this.setState({selectedCohort: {label:option?.label, value:option?.value}, cohortListName: option?.label});
		}
	}

    onChangeSamplelist=(option: SingleValue<SearchableListData>) => {
        if(option == null){
			this.setState({selectedSample: undefined, listCohortName: ""});
		}
		else{
        	this.setState({selectedSample: {label:option?.label, value:option?.value}, listCohortName: option?.label});
		}
	}

    //Main Screen Button Event
    onUploadUserSampelList =() =>{
        var modalvcfs = this.genModalVCFList(new Array<SampleList>());
        this.setState({ ListType: "",
                        ListName: "",
                        cohortListName: "",
                        SelectedListRow: undefined, 
                        selectedgroupid: undefined,
                        selectedCohort: undefined, 
                        isModalEdit:false, 
                        showModal:true, 
                        modal_blocking:false, 
                        modalVcffiles: modalvcfs,
                        hasModalerror: false,
                        activeModelKey: ActiveModelTypes.Upload });
    }

    onCreateCohortSampleList =() =>{
        var modalvcfs = this.genModalVCFList(new Array<SampleList>());
        this.setState({ ListType: "Sample",
                        ListName: "",
                        cohortListName: "",
                        SelectedListRow: undefined, 
                        selectedgroupid: undefined,
                        selectedCohort: undefined, 
                        isModalEdit:false, 
                        showModal:true, 
                        modalVcffiles: modalvcfs, 
                        hasModalerror: false,
                        activeModelKey: ActiveModelTypes.CohortList });

        if(this.usersession.Depot_cohorts == undefined || this.usersession.Depot_cohorts.length == 0){
            this.onReloadCohorts();
        }
        
    }

    onCreateSampleListCohorts =() =>{
        this.setState({ ListType: "",
                        ListName: "",
                        cohortListName: "",
                        SelectedListRow: undefined, 
                        selectedgroupid: undefined,
                        selectedCohort: undefined, 
                        isModalEdit:false, 
                        showModal:true, 
                        modal_blocking:false, 
                        hasModalerror: false,
                        activeModelKey: ActiveModelTypes.SampleCohort })
    }

    onEditList = (row: SampleList) => {
        this.nonSelectedRowIds = [];
        var rows = new Array<SampleList>(row);
        var modalvcfs = this.genModalVCFList(rows);

        this.selectedRowid = row.listid;
        row.cohortname = row.listname;
        this.oldlistname = row.listname;

        var list1 = this.alllist.find(x=>x.listid === row.listid) as SampleList;
        var list1content = list1.totallistcontent.split("\n");

        var totalDualListSampleids = [] as SearchableListData[];
        list1content.forEach(function (sampleid) {
            var item = {
                "value": sampleid,
                "label": sampleid
            } as SearchableListData;
            totalDualListSampleids.push(item);
        });

        var selectedDualListSampleids = totalDualListSampleids.map(x=>x.value);

        this.setState({ ListType: row.listtype, 
                        ListName: row.listname,
                        activeModelKey: ActiveModelTypes.Edit,
                        SelectedListRow: row, 
                        selectedgroupid: row.groupids,
                        showModal: true, 
                        isModalEdit:true, 
                        modalVcffiles: modalvcfs,
                        modal_blocking:false, 
                        newSample: "",
                        totalDualListSampleids:totalDualListSampleids,
                        selectedDualListSampleids:selectedDualListSampleids,
                        hasModalerror: false });
    }

    onDeleteList =(row: SampleList) => {
        var stateJson = {
            blocking:true,
            blockmessage:"Deleting ... ",
            errors:"",
        } as listState;
        this.setState(stateJson);

        let url = API_URL + 'listmanagement/deletelist';
        axios.post(url, { listid: row.listid })
            .then(response => {
                var response_data = response.data;
                stateJson.haserror= true;
                stateJson.errors = response_data.toString();
                this.loadList(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.haserror= true;
                stateJson.blocking = false;
                stateJson.errors = 'There was an error!' + error.message;
                this.setState(stateJson);
            });
    }

    onDownloadList =(row: SampleList) => {
        let url = API_URL + 'listmanagement/downloadlist';
        axios.post(url, { listname: row.listname },
            {
                responseType: 'arraybuffer',
                headers: {
                  "Content-Type": "application/txt",
                }
            })
            .then(response => {
                var file = new Blob([ response.data ], {
                    type : 'application/txt'
                });
              
                var fileURL = URL.createObjectURL(file);
                var a         = document.createElement('a');
                a.href        = fileURL; 
                a.target      = '_blank';
                a.download    = row.listname;
                document.body.appendChild(a);
                a.click();
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({ blocking: false });
            });
    }

    //Rule1: Merge Row must have same ListType, disable all rolws that listtype didn't match the slected Row
    //Rule2: disable rows that has no same VCF files in slected Rows.
    OnhandleselectRows =(selectedRows:Array<String>) =>{
        if(selectedRows.length === 0){   //
            this.nonSelectedRowIds=[];
        }
        else if(selectedRows.length === 1){   //Rule 1:
            var seletcedRowId = selectedRows[0];
            var seletcedRow = this.alllist.find(x => x.listid == seletcedRowId) as SampleList;

            var nonRows = this.alllist.filter(function (r) {
                return (r.listtype != seletcedRow.listtype) || (r.vcf == undefined);
            });

            this.nonSelectedRowIds = nonRows.map(function (r) {
                return (r.listid);
            })
        }
        else if(selectedRows.length > 1){  //Rule 2:
            this.nonSelectedRowIds = [];
            var vcffiles = new Array<string>();
            for (var j=0; j < selectedRows.length; ++j){
                var seletcedRowId = selectedRows[j];
                var seletcedRow = this.alllist.find(x=>x.listid == seletcedRowId) as SampleList;
                vcffiles = vcffiles.concat(seletcedRow.vcf.split(" ").map(x=>x.replaceAll("*","")));
            }

            var excluderows = this.alllist.filter(x=> !this.state.selectedRows.includes(x.listid));

            for (var j=0; j< excluderows.length; ++j){
                var existing = false;
                var row = excluderows[j];
                if(row.vcf != null){
                    var vcf = row.vcf.split(" ").map(x=>x.replaceAll("*",""));
                    for ( var v of vcffiles){
                        if(vcf.includes(v)) {
                            existing = true;
                            break;
                        };
                    }
                }
                if(!existing){
                    this.nonSelectedRowIds.push(row.listid);
                }
            }
            
        }
        this.setState({selectedRows: selectedRows});

    }

    onDuplicateSample =() =>{
        var listid1 = this.state.selectedRows[0];
        var list1 = this.alllist.find(x=>x.listid === listid1) as SampleList;
        var list1content = list1.totallistcontent.split("\n");

        var listname =list1.listname + "_copy";

        this.mergeSample(list1content, listname);
    }

    onIntersectSamples =() => {
        var listid1 = this.state.selectedRows[0];
        var list1 = this.alllist.find(x=>x.listid === listid1) as SampleList;
        var list1content = list1.totallistcontent.split("\n");

        for (let i = 1; i < this.state.selectedRows.length; i++) {
            var listid = this.state.selectedRows[i];
            var sampelist = this.alllist.find(x=>x.listid === listid) as SampleList;
            var listcontent = sampelist.totallistcontent.split("\n");

            list1content = list1content.filter(value => listcontent.includes(value));
        }
        this.mergeSample(list1content, "");
    }

    onCombineSamples =() =>{
        var list1content = Array<String>();
        for (let i = 0; i < this.state.selectedRows.length; i++) {
            var listid = this.state.selectedRows[i];
            var sampelist = this.alllist.find(x=>x.listid === listid) as SampleList;
            var listcontent = sampelist.totallistcontent.split("\n");

            list1content = list1content.concat(listcontent.filter((x) => list1content.indexOf(x) < 0));
        }

        this.mergeSample(list1content, "");
    }

    onEnterNewSample = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState ({newSample:  target.value});
    }

    onAddNewSample =() =>{
        var newsample = {
            "value": this.state.newSample,
            "label": this.state.newSample
        } as SearchableListData;
        var totalsamples = this.state.totalDualListSampleids;
        totalsamples.push(newsample);
        this.setState({totalDualListSampleids: totalsamples});
    }

    mergeSample= (list1content: String[], listname: string)=>{
        var totalDualListSampleids = [] as SearchableListData[];
        list1content.forEach(function (sampleid) {
            var item = {
                "value": sampleid,
                "label": sampleid
            } as SearchableListData;
            totalDualListSampleids.push(item);
        });

        var selectedDualListSampleids = totalDualListSampleids.map(x=>x.value);

        var selectedrows = this.alllist.filter(x=> this.state.selectedRows.includes(x.listid));
        
        var modelvcfs = this.genModalVCFList(selectedrows);
        
        this.setState({ ListType: "Sample",
                        ListName: listname,
                        cohortListName: "",
                        SelectedListRow: undefined, 
                        selectedgroupid: undefined,
                        selectedCohort: undefined, 
                        isModalEdit:false, 
                        showModal:true, 
                        modal_blocking:false, 
                        hasModalerror: false,
                        modalVcffiles: modelvcfs,
                        totalDualListSampleids:totalDualListSampleids,
                        selectedDualListSampleids:selectedDualListSampleids,
                        activeModelKey: ActiveModelTypes.Merge });
    }

    //Modal Close
    onClose =() =>{
        var stateJson = {
            modal_blocking: false,
            showModal: false,
        } as listState;
        this.setState (stateJson);
    }

    componentDidMount(){
        this.RefreshUserData();
    }


    onSelectedDualListSampleIds =(value: string[]) =>{
		this.setState({selectedDualListSampleids: value,});
	}

    //function
    checkstatus =() =>{
        if (sessionStorage.token == null || sessionStorage.token == "") {
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.usersession = JSON.parse(sessionStorage.userSession);
        if(!this.usersession.isverified){
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }   
    }

    genSearchableCohort(){
        this.searchablecohorts = Array<SearchableListData>();
		for (var i = 0; i < this.usersession.Depot_cohorts.length; i++) {
			var cohort = this.usersession.Depot_cohorts[i] as Cohort;
			this.searchablecohorts.push({label: cohort.name, value: cohort.name});
		};
    }

    genModalVCFList = (rows: SampleList[]) => {
        var modalVcffiles = Array<VCF>();
        this.vcffiles.forEach((vcf) => {
            vcf.selected = false;
            modalVcffiles.push(vcf);
        });

        if (rows.length > 0) {
            for (var i = 0; i < rows.length; ++i) {
                var row = rows[i];
                modalVcffiles.forEach((vcf) => {
                    if (row.vcfid !== undefined) {
                        var vcfid_ls = row.vcfid.split(",");
                        if (vcfid_ls.includes(vcf.vcfid)) {
                            vcf.selected = true;
                        }
                    }
                })

            }
        }
     

        return modalVcffiles;
    }

    render(){
        this.checkstatus();
        this.genSearchableCohort();
        return (
            <div className="gap-page-content">
                <BlockUi tag="div" blocking={this.state.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />}>
                    <div className="container">
                        {this.state.haserror &&
                            <div className="row redcolor">
                                <p>{this.state.errors}</p>
                            </div>
                        }
                        
                        <div className="row margin-bottom-10">
                            <div className="col-md-3">
                                <h1>List Management</h1>
                            </div>
                            <div className="main_menu-right">
                                <button id="uploaduserlist" type="button" className="btn btn-primary" onClick={this.onUploadUserSampelList}>Upload User List</button>
                                <button id="createcohortlist" type="button" className="btn btn-primary margin-left-5" onClick={this.onCreateCohortSampleList}>Create Sample List from Depot Cohorts</button>
                                <button id="createcohortlist" type="button" className="btn btn-primary margin-left-5" onClick={this.onCreateSampleListCohorts}>Create Depot Cohorts from Sample List</button>
                            </div>
                        </div>
                        <form name="userForm">
                            <hr></hr>
                            <div className="row">
                                <div className="col-md-2 margin-top-10">
                                    <h4>User Lists</h4>
                                </div>
                                <div className="col-md-7 margin-top-10 margin-left--80">
                                    <button type="button" className="btn btn-link" disabled={(this.state.selectedRows.length != 1)} onClick={() => this.onDuplicateSample()} >Duplicate List</button>
                                    <button type="button" className="btn btn-link" disabled={(this.state.selectedRows.length<2)} onClick={() => this.onIntersectSamples()} >Intersect List</button>
                                    <button type="button" className="btn btn-link" disabled={(this.state.selectedRows.length<2)} onClick={() => this.onCombineSamples()} >Combine List</button>
                               </div>
                            </div>
                            <div className="row">
                                <SampleListTable
                                    searchResults={this.alllist}
                                    onEditList={(row: SampleList) => this.onEditList(row)}
                                    onDeleteList={(row: SampleList) => this.onDeleteList(row)}
                                    onDownloadList={(row: SampleList) => this.onDownloadList(row)}
                                    preselectedrowid={this.selectedRowid}
                                    nonSelected={this.nonSelectedRowIds}
                                    OnRowSelect={(selectedRows: []) => this.OnhandleselectRows(selectedRows)}
                                />
                            </div>
                        </form>
                    </div>
                </BlockUi>

                <Modal show={this.state.showModal}
                    size="lg"
                    animation={false}
                    backdrop="static"
                    keyboard={false}>
                    <BlockUi tag="div" blocking={this.state.modal_blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />}>
                        <Modal.Header>
                            <Modal.Title>
                                {this.state.activeModelKey === ActiveModelTypes.CohortList &&
                                    <div>
                                        Create a List from Depot Cohorts
                                    </div>
                                }
                                {this.state.activeModelKey === ActiveModelTypes.Upload &&
                                    <div>
                                       Upload a List
                                    </div>
                                }
                                {this.state.activeModelKey === ActiveModelTypes.SampleCohort &&       
                                    <div>
                                        Create Depot Cohorts from Sample List
                                    </div>
                                }
                                {this.state.activeModelKey === ActiveModelTypes.Merge &&    
                                    <div> 
                                        {this.state.selectedRows.length > 1 &&
                                            <div>
                                                Intersect List
                                            </div>
                                        } 
                                        {this.state.selectedRows.length == 1 &&
                                            <div>
                                                Duplicate List
                                            </div>
                                        } 
                                    </div>
                                }
                                 {this.state.activeModelKey === ActiveModelTypes.Edit &&       
                                    <div>
                                        Edit a List
                                    </div>
                                }
                               
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.hasModalerror &&
                                <div className="row redcolor">
                                    <p>{parse(this.state.Modalerrors)}</p>
                                </div>
                            }

                            {(this.state.activeModelKey === ActiveModelTypes.Merge || this.state.activeModelKey === ActiveModelTypes.Edit) &&
                                <div>
                                    <div className="row margin-top-10">
                                        <label htmlFor="listName">List Name:</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                name="listName" id="listName"
                                                placeholder="List Name" required
                                                value={this.state.ListName} onChange={this.onEnterListName} />
                                        </div>
                                    </div>

                                    {this.state.activeModelKey === ActiveModelTypes.Edit &&
                                        <div>
                                            <div className="row">
                                                <label htmlFor="newsample">Add new Sample</label>
                                                <div>
                                                    <div style={{ display: "inline-block", width: "40%" }}>
                                                    <input type="text" className="form-control"
                                                        name="newsample" id="newsample"
                                                        placeholder="New sample" required
                                                        value={this.state.newSample} onChange={this.onEnterNewSample} />
                                                    </div>
                                                    <div className="filterbutton">
                                                        <button id="addfilter" type="button" className="btn btn-primary" onClick={this.onAddNewSample} >Add New Sample</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="rdl-control-label">Available Samples ({this.state.totalDualListSampleids.length - this.state.selectedDualListSampleids.length}) </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="margin-left-10 rdl-control-label">Selected Samples ({this.state.selectedDualListSampleids.length}) </label>
                                        </div>
                                        <DualListBox
                                            canFilter
                                            showHeaderLabels={false}
                                            options={this.state.totalDualListSampleids}
                                            selected={this.state.selectedDualListSampleids}
                                            onChange={(value) => this.onSelectedDualListSampleIds(value)}
                                        />

                                    </div>
                                </div>
                            }

                            {this.state.activeModelKey === ActiveModelTypes.Upload &&
                                <div>
                                    {!this.state.isModalEdit &&
                                        <div className="row">
                                            <label htmlFor="ltype">List Type:</label>
                                            <div className="form-group">
                                                <select
                                                    id="ltype"
                                                    className="form-select"
                                                    value={this.state.ListType}
                                                    onChange={this.onChangeListType}>
                                                    <option value="">-- please choose a List Type --</option>
                                                    {
                                                        Object.entries(ListTypes).map(([key, value], i) => (
                                                            <option key={key} value={value}>{value}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="row margin-top-10">
                                        <label htmlFor="listName">List Name:</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                name="listName" id="listName"
                                                placeholder="List Name" required
                                                value={this.state.ListName} onChange={this.onEnterListName} />
                                        </div>
                                    </div>
                                    {!this.state.isModalEdit && 
                                        <div className="row margin-top-10">
                                            <label htmlFor="listfile">List File:</label>
                                            <div className="form-group">
                                                <input id="listfile" type="file" onChange={this.onListFileChange} accept=".txt" />
                                                <ProgressBar className="margin-top-10" now={this.state.progress1} label={`${this.state.progress1}%`} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {this.state.activeModelKey === ActiveModelTypes.CohortList &&
                                <div>
                                    <form name="cohortForm">
                                        <div className="row margin-bottom-10 margin-top-10">
                                            <div>
                                                <span>
                                                    <label htmlFor="cohort_sampleList" >Depot Cohorts: &nbsp;&nbsp;</label>
                                                </span>
                                                <a className="link-primary" onClick={() => this.onReloadCohorts()} >Reload Cohorts</a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <Select className="searchableLay"
                                                inputId="cohort_sampleList"
                                                options={this.searchablecohorts}
                                                value={this.searchablecohorts.filter(({ value }) => value === this.state.selectedCohort?.value)}
                                                onChange={(option) => this.onChangeCohort(option)}
                                                isSearchable={true}
                                                isClearable={true}
                                                filterOption={createFilter({ ignoreAccents: false })}
                                                placeholder={"Select a Cohort ..."} />
                                        </div>
                                        <div className="row margin-top-10">
                                            <label htmlFor="cohortName">List Name:</label>
                                            <div className="row margin-top-5 margin-left-5">
                                                <div className="col-md-1 label_middle_right">
                                                    <span className="span_middle">Cohort-</span>
                                                </div>
                                                <div className="col-md-11">
                                                    <input type="text" className="form-control"
                                                        name="cohortName" id="cohortName"
                                                        placeholder="Sample Name" required
                                                        value={this.state.cohortListName} onChange={this.onCohortListName} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                            {this.state.activeModelKey === ActiveModelTypes.SampleCohort &&
                                <div>
                                    <div className="row">
                                        <label htmlFor="listName">List Name:</label>
                                        <div className="form-group">
                                            <Select className="searchableLay"
                                                inputId="sampleList"
                                                options={this.searchablesamples}
                                                value={this.searchablesamples.filter(({ value }) => value === this.state.selectedSample?.value)}
                                                onChange={(option) => this.onChangeSamplelist(option)}
                                                isSearchable={true}
                                                isClearable={true}
                                                filterOption={createFilter({ ignoreAccents: false })}
                                                placeholder={"Select a Sample List ..."} />
                                        </div>
                                    </div>
                                    <div className="row margin-top-10">
                                        <div className="form-group">
                                            <label htmlFor="cohortName">Cohort Name:</label>
                                            <div className="row margin-top-5 margin-left-5">
                                                <div className="col-md-1 label_middle_right">
                                                    <span className="span_middle">GAP-</span>
                                                </div>
                                                <div className="col-md-9">
                                                    <input type="text" className="form-control"
                                                        name="cohortName" id="cohortName"
                                                        placeholder="Cohort Name" required
                                                        value={this.state.listCohortName} onChange={this.onCohortName} />
                                                </div>
                                                <div className="col-md-2 label_middle_left">
                                                    <span className="span_middle">-{new Date().toISOString().slice(0, 10)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                          
                            { this.state.ListType === 'Sample' &&
                                <div className="row">
                                    <div className="col-md-6 margin-top-10">
                                        <label htmlFor="vcfFile">VCF File:</label>
                                        <div className="vcflist">
                                            {
                                                Object.entries(this.state.modalVcffiles).map(([key, value], i) => (
                                                    <label htmlFor={value.vcfid} >
                                                        <input id={value.vcfid}
                                                            className="margin-right-5"
                                                            type="checkbox"
                                                            title={value.displayname}
                                                            value={JSON.stringify(value)}
                                                            checked={value.selected}
                                                            onChange={this.onSelectVCFChange} />
                                                        {value.displayname}
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.activeModelKey !== ActiveModelTypes.SampleCohort &&
                                <div className="row">
                                    <div className="form-group margin-top-10">
                                        <label htmlFor="vcfFile">User Groups:</label>
                                        <UserGroupTable
                                            searchResults={this.allGroups}
                                            IsGroupTable={true}
                                            IsListGroup={true}
                                            IsSelectTable={true}
                                            HideSelectColumn={false}
                                            SelectedRow={this.state.selectedgroupid}
                                            OnSelect={(selectedRows: []) => this.onSelectUserGroups(selectedRows)}
                                        />
                                    </div>
                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-primary" 
                            disabled={(this.state.activeModelKey === ActiveModelTypes.Merge && (this.state.selectedDualListSampleids.length == 0 || !this.state.ListName)) ||
                            (this.state.activeModelKey === ActiveModelTypes.Upload && ((!this.state.isModalEdit && !this.state.listFile) || !this.state.ListName || !this.state.ListType)) ||
                            (this.state.activeModelKey === ActiveModelTypes.CohortList && (!this.state.selectedCohort || !this.state.cohortListName)) ||
                            (this.state.activeModelKey === ActiveModelTypes.SampleCohort && (!this.state.selectedSample || !this.state.listCohortName))}

                            onClick={this.onSaveSampleList} >Save</button>
                            <button className="btn btn-outline-secondary" onClick={this.onClose}> Close </button>
                        </Modal.Footer>
                    </BlockUi>
                </Modal>
            </div>
        )
    };
};
